import { FunctionComponent, useState, ChangeEvent, useRef, useEffect } from 'react';
import {
    Select,
    MenuItem,
    makeStyles,
    FormControl,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Button,
    Menu,
    CircularProgress,
    Grid,
} from '@material-ui/core';
import {
    faFilter,
    faPencil,
    faTrash,
    faGripVertical,
    faDownload,
    faInfoCircle,
    faUpRightAndDownLeftFromCenter,
    faDownLeftAndUpRightToCenter,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    actionsList,
    tempFilter,
    warnings,
} from '../../../constants/photos/photoConstants';
import {
    ImageData,
    EditorSectionProps,
    ImageDeleteData,
    ImageSize,
} from '../../../models/images/sharedModel';
import ShareForm from './share/SharePhotos';
import './editorSection.scss';
import { ReactSortable } from 'react-sortablejs';
import {
    updateOrder,
    formatSize,
    validateAspectRatio,
    validateImageSize,
    bytesToSize,
    getCurrentImageType,
} from '../../../utils/photos/photoUtils';
import EditMenu from './editMenu/EditMenu';
import Download from './download/DownloadPhotos';
import ImageDownload from './imageDownload/ImageDownload';
import { useAppDispatch } from '../../../../app/hooks';
import { imageTypes } from '../../../constants/imageTypes';
import {
    verifyListingEdit,
    verifyListingPhotosReorder,
} from '../../../../features/listings/utils';
import CustomModal from '../../modal';
import Flickity from 'react-flickity-component';
import FlickitySlider from '../../../component/flickitySlider/FlickitySlider';
import { googleAnalyticsAction } from '../../../functions/googleAnalytics/ga';

const useStyles = makeStyles({
    textWhite: {
        color: '#ffffff',
    },
    select: {
        '& ul': {
            fontFamily: "'MercuryTextG4Roman'",
            backgroundColor: '#002349',
            color: '#ffffff',
        },
        '& li': {
            fontFamily: "'MercuryTextG4Roman'",
            fontSize: 14,
            color: '#ffffff',
        },
    },
    imageSelected: {
        cursor: 'pointer',
    },
    tooltip: {
        maxWidth: 180,
        fontSize: 12,
        fontFamily: 'MercuryTextG4Roman',
    },
});

const ImageEditor: FunctionComponent<EditorSectionProps> = (props) => {
    const {
        currentImageType,
        currentImageDetails,
        images,
        entityId,
        filter,
        flags,
        deleteImageDetails,
        updateImages,
        saveImageSharePackage,
        downloadImagePackage,
        imageDownload,
        fetchImageTags,
        flagUpdate,
        saveImageDetails,
        saveImageCaption,
        imageTags,
        saveImageTags,
        setFormData,
        currentSection,
        saveShowcaseImages,
        saveMainPhotos,
        imageSelectCount,
        setImageSelectCount,
        uploadCount,
        setUploadCount,
        totalUploadCount,
        entityType,
        salesRegionId,
        office,
    } = props;
    const dispatch = useAppDispatch();
    const [size, setSize] = useState<number>(1);
    const [selectedAction, setSelectedAction] = useState<string>('');
    const [hover, setHover] = useState<string>('');
    const [cols, setCols] = useState<string>('small');
    const [displayInfo, setDisplayInfo] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [sortImages, setSortImages] = useState<ImageData[]>([]);
    const [image, setImage] = useState<ImageData>();
    const [isDownload, setDownload] = useState<string>('');
    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
    const classes = useStyles();

    //to open selected image in full screen
    const [fullScreenIndex, setFullScreenIndex] = useState<number>(-1);
    const flkty = useRef<Flickity | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getColSize = (value: number) => {
        switch (value) {
            case 1:
                return 'small';
            case 2:
                return 'medium';
            case 3:
                return 'large';
            default:
                return 'small';
        }
    };

    const sendGAHandler = (category: string, action: string, labelSubString: string) => {
        googleAnalyticsAction(
            category,
            action,
            `${
                entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
            }: ${labelSubString}, Image type: ${currentImageType}`,
        );
        googleAnalyticsAction(
            category,
            action,
            `${
                entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
            }: ${labelSubString}, Image type: ${currentImageType}, Office: ${office}`,
        );
    };

    const handleChange = (
        event: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        const value = parseInt(String(event.target.value));
        setSize(value);
        sendGAHandler(
            'Drop Down Change',
            'Select',
            `Thumbnail size ${getColSize(value)} selected`,
        );
        setCols(getColSize(value));
    };

    const [selectedImagesList, setSelectedImagesList] = useState<ImageData[]>([]);
    const imageSelectHandler = (img: ImageData) => {
        const index = selectedImagesList.findIndex(
            (item: ImageData) => item.guid === img.guid,
        );
        if (index > -1) {
            const updatedSelectedImageList = selectedImagesList;
            updatedSelectedImageList.splice(index, 1);
            setSelectedImagesList([...updatedSelectedImageList]);
            setImageSelectCount(imageSelectCount - 1);
        } else {
            if (
                currentImageType !== imageTypes.ShowcaseHeroImages ||
                imageSelectCount < 5
            ) {
                setSelectedImagesList([...selectedImagesList, img]);
                setImageSelectCount(imageSelectCount + 1);
            }
        }
    };

    useEffect(() => {
        if (
            sortImages &&
            sortImages.length > 0 &&
            (imageSelectCount === sortImages.length ||
                (currentImageType === imageTypes.ShowcaseHeroImages &&
                    selectedImagesList.length === 5))
        ) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [imageSelectCount, currentImageType, selectedImagesList.length, sortImages]);

    const clearHandler = () => {
        setSelectedImagesList([]);
        setImageSelectCount(0);
        setSelectAll(false);
        sendGAHandler('Check Box', 'Check', 'Clear all images selected');
    };

    const selectAllHandler = () => {
        if (selectAll === false) {
            if (currentImageType !== imageTypes.ShowcaseHeroImages) {
                setSelectedImagesList([...sortImages]);
                setImageSelectCount(sortImages.length);
            } else {
                setSelectedImagesList([...sortImages].splice(0, 5));
                setImageSelectCount([...sortImages].splice(0, 5).length);
            }
            setSelectAll(true);
        } else if (selectAll === true) {
            clearHandler();
            setSelectAll(false);
        }
        sendGAHandler(
            'Check Box',
            'Check',
            `Select all images ${selectAll === false ? 'checked' : 'unchecked'}`,
        );
    };

    const deleteAction = (ids: string[]) => {
        const deleteData: ImageDeleteData = {
            ids: ids,
            imageType: currentImageType,
            shouldValidate:
                (currentImageType === imageTypes.Buildings ||
                    currentImageType === imageTypes.MainPhotos) &&
                currentImageDetails.photosLoaded - ids.length === 0,
        };
        dispatch(deleteImageDetails(deleteData));
        sendGAHandler('Delete', 'Click', 'Image(s) deleted');
    };

    const deleteHandler = () => {
        if (selectedImagesList.length > 0) {
            const idsToBeDeleted: string[] = [];
            setSelectedAction(actionsList.delete);
            selectedImagesList.forEach((img: ImageData) => {
                idsToBeDeleted.push(img.guid);
            });
            deleteAction(idsToBeDeleted);
            clearHandler();
        }
    };

    const highLightImage = (img: ImageData): boolean => {
        return selectedImagesList.find((item) => item.guid === img.guid) ? true : false;
    };

    useEffect(() => {
        const allImages = images;
        if (allImages && allImages.length >= 0) {
            setSortImages(allImages);
        }
    }, [images, currentImageType]);

    const updatedOrder = () => {
        if (!isEdit && !isFiltered && !flags.selectionFromMainPhoto) {
            const orderedImages = updateOrder(sortImages);
            updateImages({
                data: orderedImages,
                imageType: currentImageType,
            });
            setFormData(orderedImages, currentSection);
            sendGAHandler('Reorder', 'Drag And Drop', 'Image(s) reordered');
        }
    };

    const cancelHandler = () => {
        setIsEdit(false);
    };

    const shareHandler = () => {
        if (selectedImagesList.length > 0) {
            setSelectedAction(actionsList.share);
            sendGAHandler('Save', 'Click', 'Image(s) shared');
        }
    };

    const downloadHandler = () => {
        if (selectedImagesList.length > 0) {
            setSelectedAction(actionsList.download);
        }
    };

    const downloadAction = (id: string) => {
        id === isDownload ? setDownload('') : setDownload(id);
    };

    const handleSelectedFilter = (choosen: string) => {
        setAnchorEl(null);
        if (selectedFilter.indexOf(choosen) === -1) {
            const value = [];
            value.push(choosen);
            setSelectedFilter([...selectedFilter, ...value]);
        } else {
            const updatedFilter = selectedFilter.filter((data) => {
                return data !== choosen;
            });
            setSelectedFilter(updatedFilter);
        }
        sendGAHandler('Drop Down Change', 'Select', `Set filter ${choosen} selected`);
    };

    useEffect(() => {
        let updatedImage: ImageData[];
        if (selectedFilter.length > 0) {
            setIsFiltered(true);
            updatedImage = images.filter(
                (data) => data.tagName && selectedFilter.indexOf(data.tagName) !== -1,
            );
        } else {
            setIsFiltered(false);
            updatedImage = images;
        }

        setSortImages(updatedImage);
    }, [selectedFilter, images]);

    const handleMainPhotoAndCancel = (imageType: string) => {
        flagUpdate(
            {
                property: 'selectionFromMainPhoto',
                value: !flags.selectionFromMainPhoto,
            },
            imageType,
        );
        setSortImages(images);
        clearHandler();
    };
    const handleSaveButton = async (newImageType: string) => {
        if (saveShowcaseImages && !newImageType.length) {
            sendGAHandler('Save', 'Click', 'Choose from Main Photos save clicked');
            await saveShowcaseImages(selectedImagesList);
        }
        if (!!newImageType && saveMainPhotos) {
            sendGAHandler(
                'Save',
                'Click',
                `Send to ${
                    newImageType === imageTypes.MainPhotos ? 'main photos' : 'floor plans'
                } selected`,
            );
            await saveMainPhotos(selectedImagesList, newImageType);
        }
        if (!newImageType.length) {
            flagUpdate({
                property: 'selectionFromMainPhoto',
                value: !flags.selectionFromMainPhoto,
            });
        }
        clearHandler();
        if (!!newImageType && setUploadCount) {
            setUploadCount(1);
        }
    };

    useEffect(() => {
        if (image) {
            const croppedImage = sortImages.find(
                (item) => item.orderNo === image.orderNo && item.guid !== image.guid,
            );
            if (croppedImage) {
                setImage(croppedImage);
            } else {
                const updatedImage = sortImages.find((item) => item.guid === image.guid);
                if (updatedImage) {
                    setImage(updatedImage);
                }
            }
        }
    }, [sortImages, image]);

    // check and move below one
    // useEffect(() => {
    //     if (currentImageType === imageTypes.MainPhotos) {
    //         const mainStateUpdate: CustomKey = {
    //             imagesCount: currentImageDetails.photosLoaded,
    //         };
    //         dispatch(updateMainStateData(mainStateUpdate));
    //     }
    // }, [currentImageType, currentImageDetails, dispatch]);

    const getLoader = (flagValue: string, loaderText: string, showCount?: boolean) => {
        return (
            <>
                {flags[flagValue as keyof typeof flags] ? (
                    <div className="circularOverlay" style={{ width: '85%' }}>
                        <span className="saveShowcaseText">
                            {loaderText}{' '}
                            {showCount ? `${uploadCount} of ${totalUploadCount}` : ''}
                        </span>
                        <CircularProgress />
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    };

    // for visual cue of image
    const displayWarning = (size: ImageSize) => {
        return (
            !validateAspectRatio(size.width, size.height) ||
            !validateImageSize(size.width, size.height)
        );
    };

    // return style for image thumbnail based on size
    const getStyleForThumbnail = (image: ImageData) => {
        const showWarning = displayWarning(image.size);
        if (image.caption && showWarning) {
            return {
                bottom: '-25%',
                height: '50%',
            };
        }
        if (image.caption && !showWarning) {
            return {
                bottom: '-15%',
                height: '30%',
            };
        }
        if (!image.caption && showWarning) {
            return {
                bottom: '-20%',
                height: '40%',
            };
        }
        return {
            bottom: '-10%',
            height: '20%',
        };
    };

    const getTooltipAction = (
        toolTipTitle: string,
        toolTipIcon: IconDefinition,
        clickEvent?: React.MouseEventHandler<SVGSVGElement> | undefined,
    ) => {
        return (
            <Tooltip
                title={toolTipTitle}
                classes={{
                    tooltip: classes.tooltip,
                }}
                arrow
                placement="bottom"
            >
                <span>
                    <FontAwesomeIcon
                        className={size === 1 ? 'fileIcon' : 'fileIcon mediumFileIcon'}
                        icon={toolTipIcon}
                        onClick={(event) => {
                            if (clickEvent) {
                                clickEvent(event);
                            }
                        }}
                    />
                </span>
            </Tooltip>
        );
    };

    const getSeparator = (image: ImageData) => {
        const showWarning = displayWarning(image.size);
        if (image.caption && showWarning) {
            return '- ';
        }
        return '';
    };

    const getWarningText = (size: ImageSize) => {
        if (!validateImageSize(size.width, size.height)) {
            return 'Photo does not meet minimum resolution requirement';
        }
        return `This image is not in ${
            size.width > size.height ? '3x2' : '2x3'
        } aspect ratio.`;
    };

    const isFileImage = (file: ImageData): boolean => {
        return !file.type.includes('pdf');
    };

    const getFileName = (file: ImageData): string => {
        return !file.type.includes('pdf') ? 'Image' : 'File';
    };

    // to update in master branch as well
    const getCarouselData = (images: ImageData[]) => {
        return images
            .filter((im) => isFileImage(im))
            .map((image) => {
                return { imageUrl: image.urls.originalUrl };
            });
    };

    // will return div content based on file type
    const getFullscreenItem = () => {
        const imageFullscreen = (
            <div className={'imageContainer imageContainerFullScreen'}>
                <div className="fullScreenIcon">
                    <span>
                        <FontAwesomeIcon
                            className="fileIcon"
                            icon={faDownLeftAndUpRightToCenter}
                            onClick={() => {
                                setFullScreenIndex(-1);
                                sendGAHandler(
                                    'Cancel',
                                    'Click',
                                    'Exit Image full screen selected',
                                );
                            }}
                            title={'Exit Fullscreen'}
                        />
                    </span>
                </div>
                <FlickitySlider
                    images={getCarouselData(sortImages)}
                    flkty={flkty}
                    options={
                        fullScreenIndex > -1 ? { initialIndex: fullScreenIndex } : {}
                    }
                    isFullScreen={false}
                    disableDefaultFullScreen={true}
                />
            </div>
        );
        const pdfFullscreen = (
            <div className={'imageContainer imageContainerFullScreen'}>
                <object
                    aria-label="pdf-fullscreen"
                    data={`${image?.urls.originalUrl}#toolbar=0&navpanes=0`}
                    type="application/pdf"
                    height="100%"
                    width="100%"
                ></object>
                <div className="fullScreenIcon">
                    <span>
                        <FontAwesomeIcon
                            className="fileIcon"
                            icon={faDownLeftAndUpRightToCenter}
                            onClick={() => {
                                setFullScreenIndex(-1);
                                sendGAHandler(
                                    'Cancel',
                                    'Click',
                                    'Exit Image full screen selected',
                                );
                            }}
                            title={'Exit Fullscreen'}
                        />
                    </span>
                </div>
            </div>
        );

        return image?.type.includes('pdf') ? pdfFullscreen : imageFullscreen;
    };

    return (
        <div className="imageEditorContainer">
            {getLoader('savingShowcaseImages', 'Saving Showcase Hero Images')}
            {getLoader('sendToMainPhotos', 'Sending to Main Photos', true)}
            {getLoader('sendToFP', 'Sending to Floor Plans', true)}
            {getLoader('deleting', 'Deleting')}
            <div className="toolbar">
                <span className="totalImages">Total Images: {sortImages.length}</span>
                <span className="utilities">
                    <span className="size-holder">
                        THUMBNAIL SIZE:
                        <span className="sizes">
                            <FormControl className="sizeList">
                                <Select
                                    classes={{
                                        root: classes.textWhite,
                                        icon: classes.textWhite,
                                    }}
                                    name="filter"
                                    value={size}
                                    onChange={handleChange}
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    disableUnderline
                                    style={{
                                        fontFamily: 'MercuryTextG4Roman',
                                        fontSize: '14px',
                                        color: '#ffffff',
                                    }}
                                >
                                    {tempFilter.map((item) => (
                                        <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </span>
                    </span>

                    {filter && filter.length > 0 ? (
                        <span className="filter">
                            <Button
                                id="demo-positioned-button"
                                onClick={handleClick}
                                className="filterImages"
                            >
                                <span className="filterText">Set Filter </span>
                                <FontAwesomeIcon className="fileIcon" icon={faFilter} />
                            </Button>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {filter.map((item) => (
                                    <MenuItem
                                        onClick={() => {
                                            handleSelectedFilter(item.name);
                                        }}
                                        value={item.id}
                                        key={item.id}
                                        className={
                                            selectedFilter.indexOf(item.name) > -1
                                                ? 'filterSelected'
                                                : ''
                                        }
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </span>
                    ) : (
                        ''
                    )}
                </span>
            </div>

            <div className="noteText">
                Note: Images below may be subject to quality control review.{' '}
                <a
                    href="https://access.sir.com/content/sir/en/services-operations/Resources/policiesprocedures/StyleGuide.html"
                    target="_blank"
                    onClick={() => {
                        sendGAHandler('Navigation', 'Click', 'Style guide link clicked');
                    }}
                >
                    View Quality control standards and guidelines (Style Guide)
                </a>
                . Photos must be color jpg/jpeg/png (floorplans may also be pdf), at least
                1800x1200 pixels. A 3x2 aspect ratio is recommended, or images may appear
                stretched when displayed on the web.
            </div>
            {currentImageType !== imageTypes.ShowcaseHeroImages ? (
                <div className="actionBar">
                    <span
                        className={
                            selectedImagesList.length > 0
                                ? 'actionClick share actionSelected'
                                : 'actionClick share'
                        }
                        onClick={shareHandler}
                    >
                        share
                    </span>
                    <span
                        className={
                            selectedImagesList.length > 0
                                ? 'actionClick actionSelected'
                                : 'actionClick'
                        }
                        onClick={downloadHandler}
                    >
                        download
                    </span>
                    {(props.from === 'listing' &&
                        verifyListingEdit() &&
                        !verifyListingPhotosReorder()) ||
                    props.from === 'development' ? (
                        <span
                            className={
                                selectedImagesList.length > 0
                                    ? 'actionClick actionSelected'
                                    : 'actionClick'
                            }
                            onClick={deleteHandler}
                        >
                            delete
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
            {selectedAction === actionsList.share && selectedImagesList.length > 0 && (
                <ShareForm
                    selectedImagesList={selectedImagesList}
                    entityId={entityId}
                    sharing={flags.sharing}
                    resetAction={setSelectedAction}
                    clearHandler={clearHandler}
                    currentImageType={getCurrentImageType(currentImageType)}
                    saveImageSharePackage={saveImageSharePackage}
                    entityType={entityType}
                    office={office}
                />
            )}
            {selectedAction === actionsList.download && selectedImagesList.length > 0 && (
                <Download
                    selectedImagesList={selectedImagesList}
                    resetAction={setSelectedAction}
                    clearHandler={clearHandler}
                    downloading={flags.downloading}
                    currentImageType={getCurrentImageType(currentImageType)}
                    downloadImagePackage={downloadImagePackage}
                    salesRegionId={salesRegionId}
                    imageDownload={imageDownload}
                    entityType={entityType}
                    office={office}
                />
            )}
            <div className="actionSection">
                <div className="selectionHolder">
                    <span className="photoInfo">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Display Photo Info',
                                    }}
                                    checked={displayInfo}
                                    className="checkbox"
                                    onChange={(e) => {
                                        setDisplayInfo(!displayInfo);
                                        sendGAHandler(
                                            'Check Box',
                                            'Check',
                                            `Display Photo Info ${
                                                e.target.checked ? 'checked' : 'unchecked'
                                            }`,
                                        );
                                    }}
                                />
                            }
                            label={
                                <div className="checkboxLabel">Display Photo Info</div>
                            }
                            labelPlacement="end"
                        />
                    </span>
                    <span className="selections">
                        <span className="imagesSelected">
                            {imageSelectCount} Images Selected
                        </span>
                        <span className="selectAll">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{
                                            'aria-label': 'Select All',
                                        }}
                                        checked={selectAll}
                                        className="checkbox"
                                        onChange={selectAllHandler}
                                    />
                                }
                                label={<div className="checkboxLabel">Select All</div>}
                                labelPlacement="end"
                            />
                        </span>
                        <span className="clearAll" onClick={clearHandler}>
                            Clear All
                        </span>
                    </span>
                </div>
            </div>
            {currentImageType === imageTypes.UploadedImages ? (
                <Grid item container xs={12} md={12} justifyContent="flex-end">
                    <Button
                        onClick={() => handleSaveButton(imageTypes.MainPhotos)}
                        className="chooseMainPhoto"
                        disabled={!selectedImagesList.length}
                    >
                        Send to Main Photos
                    </Button>
                    <Button
                        onClick={() => handleSaveButton(imageTypes.FloorPlan)}
                        className="chooseMainPhoto"
                        disabled={!selectedImagesList.length}
                    >
                        Send to Floor Plans
                    </Button>
                </Grid>
            ) : (
                ''
            )}
            <div className="imagesSection">
                {sortImages.length ? (
                    <ReactSortable
                        list={sortImages.map((x) => ({ ...x, id: x.orderNo }))}
                        setList={(images) => {
                            setSortImages(images);
                        }}
                        animation="200"
                        easing="ease-out"
                        group="cards"
                        disabled={isEdit || isFiltered || flags.selectionFromMainPhoto}
                        onEnd={(event, sortable) => {
                            updatedOrder();
                        }}
                        className="sortHolder"
                        filter=".editHolder"
                        forceFallback={true}
                    >
                        {sortImages.length > 0
                            ? sortImages.map((item: ImageData, index) => (
                                  <>
                                      {' '}
                                      <div
                                          style={{ order: index + 1 }}
                                          key={index}
                                          onClick={() => imageSelectHandler(item)}
                                          className={
                                              highLightImage(item)
                                                  ? `${cols} imageHolder selectedImageItem`
                                                  : `${cols} imageHolder`
                                          }
                                          onMouseOver={() => setHover(item.guid)}
                                          onMouseOut={() => setHover('')}
                                      >
                                          <img
                                              className={`${
                                                  isFileImage(item)
                                                      ? !item.islandscape
                                                          ? 'portrait'
                                                          : ''
                                                      : 'portrait'
                                              } ${classes.imageSelected}`}
                                              src={
                                                  item.urls.smallUrl.includes('pdf')
                                                      ? '/tempProfile/pdf-default.png'
                                                      : item.urls.smallUrl
                                              }
                                              alt={item.caption ? item.caption : ''}
                                              loading="lazy"
                                          />
                                          <div className="imageInfo">
                                              <span
                                                  className={
                                                      hover === item.guid || displayInfo
                                                          ? 'imageDetailTop imageDetailTopVisible'
                                                          : 'imageDetailTop'
                                                  }
                                              >
                                                  <span
                                                      className={
                                                          size === 1 ? 'smallFont' : ''
                                                      }
                                                  >
                                                      <FontAwesomeIcon
                                                          className="fileIcon"
                                                          icon={faGripVertical}
                                                      />
                                                      {typeof item.size.sizeKb ===
                                                      'string'
                                                          ? bytesToSize(
                                                                parseInt(
                                                                    item.size.sizeKb,
                                                                ),
                                                            )
                                                          : formatSize(
                                                                Number(item.size.sizeKb),
                                                            )}
                                                      {isFileImage(item)
                                                          ? `, ${item.size.width}X
                                                      ${item.size.height}`
                                                          : ''}
                                                  </span>
                                              </span>
                                          </div>
                                          {isDownload === item.guid && (
                                              <ImageDownload
                                                  image={image}
                                                  currentImageType={getCurrentImageType(
                                                      currentImageType,
                                                  )}
                                                  cancelHandler={() => setDownload('')}
                                                  size={size}
                                                  imageDownload={imageDownload}
                                                  entityType={entityType}
                                                  office={office}
                                              />
                                          )}
                                          <div
                                              className="imageInfo actionIcons"
                                              style={getStyleForThumbnail(item)}
                                          >
                                              {currentImageType !==
                                                  imageTypes.ShowcaseHeroImages ||
                                              !flags.selectionFromMainPhoto ? (
                                                  <span className="actionIconsBottom">
                                                      <span
                                                          className={
                                                              size === 1
                                                                  ? 'captionHolder smallFont'
                                                                  : 'captionHolder smallFont mediumFont'
                                                          }
                                                      >
                                                          {isFileImage(item) &&
                                                          displayWarning(item.size) ? (
                                                              <div className="captionText warningText">
                                                                  {getWarningText(
                                                                      item.size,
                                                                  )}
                                                              </div>
                                                          ) : (
                                                              ''
                                                          )}
                                                      </span>
                                                      <span
                                                          className={
                                                              hover === item.guid ||
                                                              displayInfo
                                                                  ? 'imageActionIconHolder imageActionIconHolderVisible'
                                                                  : 'imageActionIconHolder'
                                                          }
                                                      >
                                                          <span
                                                              className={
                                                                  size === 1
                                                                      ? 'smallFont'
                                                                      : 'smallFont mediumFont'
                                                              }
                                                          >
                                                              <span className="captionText">
                                                                  {getSeparator(item)}
                                                                  {item.caption}
                                                              </span>
                                                          </span>
                                                          <span className="allIconsHolder">
                                                              {(props.from ===
                                                                  'listing' &&
                                                                  verifyListingEdit() &&
                                                                  !verifyListingPhotosReorder()) ||
                                                              props.from ===
                                                                  'development' ? (
                                                                  <>
                                                                      {getTooltipAction(
                                                                          `${getFileName(
                                                                              item,
                                                                          )} Delete`,
                                                                          faTrash,
                                                                          (event) => {
                                                                              event.stopPropagation();
                                                                              deleteAction(
                                                                                  [
                                                                                      item.guid,
                                                                                  ],
                                                                              );
                                                                          },
                                                                      )}
                                                                      {isFileImage(
                                                                          item,
                                                                      ) ? (
                                                                          <>
                                                                              |
                                                                              {getTooltipAction(
                                                                                  'Image Edit like Crop, Update Caption, Update Tag etc.',
                                                                                  faPencil,
                                                                                  (
                                                                                      event,
                                                                                  ) => {
                                                                                      event.stopPropagation();
                                                                                      setIsEdit(
                                                                                          true,
                                                                                      );
                                                                                      setImage(
                                                                                          item,
                                                                                      );
                                                                                      sendGAHandler(
                                                                                          'Edit',
                                                                                          'Click',
                                                                                          'Image edit clicked',
                                                                                      );
                                                                                  },
                                                                              )}
                                                                          </>
                                                                      ) : (
                                                                          ''
                                                                      )}
                                                                      |{' '}
                                                                  </>
                                                              ) : (
                                                                  <></>
                                                              )}
                                                              {getTooltipAction(
                                                                  `${getFileName(
                                                                      item,
                                                                  )} Download`,
                                                                  faDownload,
                                                                  (event) => {
                                                                      event.stopPropagation();
                                                                      setImage(item);
                                                                      if (
                                                                          isFileImage(
                                                                              item,
                                                                          )
                                                                      ) {
                                                                          downloadAction(
                                                                              item.guid,
                                                                          );
                                                                      } else {
                                                                          sendGAHandler(
                                                                              'Download',
                                                                              'Click',
                                                                              'File downloaded',
                                                                          );
                                                                          dispatch(
                                                                              imageDownload(
                                                                                  item
                                                                                      .urls
                                                                                      .originalUrl,
                                                                              ),
                                                                          );
                                                                      }
                                                                  },
                                                              )}
                                                              <>
                                                                  |
                                                                  {getTooltipAction(
                                                                      `${
                                                                          isFileImage(
                                                                              item,
                                                                          )
                                                                              ? 'Image Fullscreen View'
                                                                              : 'Preview'
                                                                      }`,
                                                                      faUpRightAndDownLeftFromCenter,
                                                                      () => {
                                                                          fullScreenIndex ===
                                                                          -1
                                                                              ? setFullScreenIndex(
                                                                                    index,
                                                                                )
                                                                              : setFullScreenIndex(
                                                                                    -1,
                                                                                );
                                                                          setImage(item);
                                                                          sendGAHandler(
                                                                              'View',
                                                                              'Click',
                                                                              'Image full screen selected',
                                                                          );
                                                                      },
                                                                  )}
                                                              </>
                                                              {isFileImage(item) ? (
                                                                  <>
                                                                      {!validateAspectRatio(
                                                                          item.size.width,
                                                                          item.size
                                                                              .height,
                                                                      ) ? (
                                                                          <>
                                                                              |
                                                                              {getTooltipAction(
                                                                                  item
                                                                                      .size
                                                                                      .width >
                                                                                      item
                                                                                          .size
                                                                                          .height
                                                                                      ? warnings
                                                                                            .wrongAspectRatio
                                                                                            .landscape
                                                                                      : warnings
                                                                                            .wrongAspectRatio
                                                                                            .portrait,
                                                                                  faInfoCircle,
                                                                              )}
                                                                          </>
                                                                      ) : !validateImageSize(
                                                                            item.size
                                                                                .width,
                                                                            item.size
                                                                                .height,
                                                                        ) ? (
                                                                          <>
                                                                              |
                                                                              {getTooltipAction(
                                                                                  warnings.smallImage +
                                                                                      ' ' +
                                                                                      currentImageDetails
                                                                                          .requiredSize
                                                                                          .minLongSide +
                                                                                      'x' +
                                                                                      currentImageDetails
                                                                                          .requiredSize
                                                                                          .minShortSide +
                                                                                      ' pixels.',
                                                                                  faInfoCircle,
                                                                              )}
                                                                          </>
                                                                      ) : (
                                                                          <></>
                                                                      )}
                                                                  </>
                                                              ) : (
                                                                  ''
                                                              )}
                                                          </span>
                                                      </span>
                                                  </span>
                                              ) : (
                                                  <></>
                                              )}
                                          </div>
                                          <span
                                              className={
                                                  highLightImage(item)
                                                      ? 'numberBadge highlightedBadge'
                                                      : 'numberBadge'
                                              }
                                          >
                                              {index + 1}
                                          </span>
                                      </div>
                                      {isEdit && item.guid === image?.guid ? (
                                          <CustomModal
                                              width="max"
                                              title={`${image?.orderNo}`}
                                              show={isEdit}
                                              onClose={cancelHandler}
                                              customBoxStyle={{
                                                  width: '70%',
                                                  backgroundColor: '#FFFFFF',
                                              }}
                                              customModalStyle={{ margin: '15px' }}
                                          >
                                              <EditMenu
                                                  image={image}
                                                  currentImageType={currentImageType}
                                                  cancelHandler={cancelHandler}
                                                  fetchImageTags={fetchImageTags}
                                                  flagUpdate={flagUpdate}
                                                  saveImageDetails={saveImageDetails}
                                                  flags={flags}
                                                  imageDownload={imageDownload}
                                                  saveImageCaption={saveImageCaption}
                                                  currentImageDetails={
                                                      currentImageDetails
                                                  }
                                                  imageTags={imageTags}
                                                  saveImageTags={saveImageTags}
                                                  entityType={entityType}
                                                  office={office}
                                              />
                                          </CustomModal>
                                      ) : (
                                          ''
                                      )}
                                  </>
                              ))
                            : ''}
                    </ReactSortable>
                ) : (
                    <></>
                )}
            </div>
            {verifyListingEdit() &&
            !verifyListingPhotosReorder() &&
            currentImageType === imageTypes.ShowcaseHeroImages ? (
                <Grid item container xs={12} md={12} justifyContent="flex-end">
                    {!flags.selectionFromMainPhoto ? (
                        <Button
                            onClick={() => {
                                handleMainPhotoAndCancel(imageTypes.MainPhotos);
                                sendGAHandler(
                                    'View',
                                    'Click',
                                    'Choose from Main Photos clicked',
                                );
                            }}
                            className="chooseMainPhoto"
                            disabled={
                                currentImageDetails.photosLoaded ===
                                parseInt(currentImageDetails.maxPhotos)
                            }
                        >
                            Choose From Main Photos
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={() => handleSaveButton('')}
                                className="saveButton"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    handleMainPhotoAndCancel(
                                        imageTypes.ShowcaseHeroImages,
                                    );
                                    sendGAHandler(
                                        'Cancel',
                                        'Click',
                                        'Choose from Main Photos cancel clicked',
                                    );
                                }}
                                className="cancelButton"
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </Grid>
            ) : (
                <></>
            )}
            {fullScreenIndex > -1 ? (
                <div className={' imageEditMenuHolderFullScreen fromList'}>
                    <div className={'editMenuHolder editMenuHolderFullScreen'}>
                        {getFullscreenItem()}
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default ImageEditor;
