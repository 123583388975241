import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { cmrDetails } from '../../../features/cmr/cmrSlice';
import './header.scss';

export default function Nav(props: { customUrl?: string }): JSX.Element {
    const { listingdata } = useAppSelector(cmrDetails);
    const [siteLogo, setSiteLogo] = useState('/img/logo-sothebys-white.png');
    const [logoClass, setlogoClass] = useState('logo');
    useEffect(() => {
        if (listingdata.salesRegionId === 20) {
            setSiteLogo(`/img/logo-sothebys-martha-white.png`);
            setlogoClass('logo marthaLogo');
        } else if (listingdata.officeId === 157) {
            setSiteLogo(`/img/logo-sothebys-eq-white.png`);
            setlogoClass('logo marthaLogo');
        } else if (listingdata.officeId !== 0 && listingdata.salesRegionId !== 20) {
            setSiteLogo(`/img/logo-sothebys-white.png`);
            setlogoClass('logo');
        }
    }, [listingdata]);

    return (
        <div>
            <div className="headerHolder">
                <div className="header">
                    <div className="logo-wrapper">
                        <a
                            aria-label="Sotheby's International Realty home"
                            href={`${props.customUrl ?? '!#'}`}
                        >
                            <img className={logoClass} src={siteLogo} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
