import { FunctionComponent } from 'react';
import { DrawerContainerProps } from '../../../listingModels';
import { useAppDispatch } from '../../../../../../app/hooks';
import { VirtualTourUrls } from '../../../../../../shared/models/listing/virtualTourModels';
import { setCurrentFormData } from '../../../listingSlice';
import { editFormComponent } from '../../../listingConstants';
import VirtualTourTemplate from '../../../../../../shared/component/listing/virtualTour/VirtualTour';

const VirtualTour: FunctionComponent<DrawerContainerProps> = ({ editFormData }) => {
    const initialData: VirtualTourUrls = {
        virtualTourUrl: editFormData.data.virtualTourUrl,
        interactiveFloorPlanUrl: editFormData.data.interactiveFloorPlanUrl,
        virtualTour3dUrl: editFormData.data.virtualTour3dUrl,
    };

    const dispatch = useAppDispatch();

    const urlChangeHandler = (data: VirtualTourUrls) => {
        dispatch(
            setCurrentFormData({
                formData: data,
                listingEditFormName: editFormComponent.virtualTour,
            }),
        );
    };

    return <VirtualTourTemplate urls={initialData} onUrlsChange={urlChangeHandler} />;
};
export default VirtualTour;
