import Nav from './Nav';

export default function NavContainer(props: {
    token?: string;
    redirectUrl: string;
    from?: string;
    customUrl?:string
}): JSX.Element {
    if (!props.token && !props?.token?.length) {
        return <div />;
    }
    // if (props.redirectUrl !== 'undefined') {
    //     return (
    //         <Button
    //             onClick={(e) => {
    //                 window.location.href = `${props.redirectUrl}`;
    //             }}
    //         >
    //             Back {props.from && props.from !== '' ? `to ${props.from}` : ''}
    //         </Button>
    //     );
    // } else
    else {
        return <Nav customUrl={props.customUrl}/>;
    }
}
