import { postCallRaw, putCallRaw } from '../../../utils/api/apiWrapper';
import {
    ListingsRequestAPI,
    UpdateListingCommissionRequest,
} from '../../models/listingCommission/listingCommissionModels';
import { omit } from 'lodash';

export const updateListingCommissionApi = (
    request: UpdateListingCommissionRequest,
): Promise<Response> => {
    return putCallRaw(`listing/${request.id}/commission`, omit(request, ['id']));
};

export function exportCommApi(data: ListingsRequestAPI): Promise<Response> {
    const url = 'listing/commissionExport';

    return postCallRaw(url, data);
}
