import { FunctionComponent, useState } from 'react';
import { selectionSizes } from '../../../../constants/photos/photoConstants';
import { PackageProps, ImageData } from '../../../../models/images/sharedModel';
import {
    getPhotoId,
    dynamicResize,
    getImageDimension,
} from '../../../../utils/photos/photoUtils';
import { CircularProgress } from '@material-ui/core';
import './downloadPhotos.scss';
import { useAppDispatch } from '../../../../../app/hooks';
import { AppThunk } from '../../../../../app/store';
import { googleAnalyticsAction } from '../../../../functions/googleAnalytics/ga';

interface DownloadProps {
    selectedImagesList: ImageData[];
    resetAction: React.Dispatch<React.SetStateAction<string>>;
    clearHandler: () => void;
    downloading: boolean;
    currentImageType: string;
    downloadImagePackage: (url: string, request: PackageProps) => AppThunk;
    salesRegionId?: number | null;
    imageDownload: (url: string) => AppThunk;
    entityType: string;
    office: string | null;
}

const Download: FunctionComponent<DownloadProps> = ({
    selectedImagesList,
    resetAction,
    clearHandler,
    downloading,
    currentImageType,
    downloadImagePackage,
    salesRegionId,
    imageDownload,
    entityType,
    office,
}) => {
    const [dimensions, setDimensions] = useState<string>('');
    const dispatch = useAppDispatch();

    const exitDownload = () => {
        resetAction('');
        clearHandler();
    };

    const downloadHandler = async () => {
        if (selectedImagesList.length > 0) {
            const idsToBeDownloaded: string[] = [];
            selectedImagesList.forEach((img: ImageData) => {
                if (img.urls.originalUrl.includes('pdf')) {
                    idsToBeDownloaded.push(getPhotoId(img, '', currentImageType));
                } else {
                    // To handle street easy aspect ratio
                    if (dimensions === selectionSizes.streetEasy) {
                        const resizedDimensions = dynamicResize(
                            img.size.width,
                            img.size.height,
                        );
                        idsToBeDownloaded.push(
                            getPhotoId(
                                img,
                                `${resizedDimensions.width}x${resizedDimensions.height}_w`,
                                currentImageType,
                            ),
                        );
                    } else {
                        idsToBeDownloaded.push(
                            getPhotoId(
                                img,
                                getImageDimension(img.islandscape, dimensions),
                                currentImageType,
                            ),
                        );
                    }
                }
            });
            if (idsToBeDownloaded.length === 1) {
                await dispatch(
                    imageDownload(
                        `${process.env.REACT_APP_PHOTOURL}/${idsToBeDownloaded[0]}`,
                    ),
                );
            } else {
                const url = `${process.env.REACT_APP_PHOTOURL}/api/asset/package`;
                const payload: PackageProps = {
                    files: idsToBeDownloaded,
                };
                await dispatch(downloadImagePackage(url, payload));
            }
            googleAnalyticsAction(
                'Download',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image(s) downloaded, Image type: ${currentImageType}`,
            );
            googleAnalyticsAction(
                'Download',
                'Click',
                `${
                    entityType === 'listing' ? 'Listing Edit' : 'Development Edit'
                }: Image(s) downloaded, Image type: ${currentImageType}, Office: ${office}`,
            );
            exitDownload();
        }
    };

    return (
        <div className="downloadContainer">
            {downloading ? (
                <div className="circularOverlay">
                    <span className="downloadingText">Preparing images to download</span>
                    <CircularProgress />
                </div>
            ) : (
                ''
            )}
            <div className="buttonsHolder">
                <span className="sizeText">Choose Size:</span>
                <button
                    className={
                        dimensions === selectionSizes.small
                            ? 'sizeButton sizeButtonSelected'
                            : 'sizeButton'
                    }
                    onClick={() => setDimensions(selectionSizes.small)}
                >
                    1800X1200
                </button>
                <button
                    className={
                        dimensions === selectionSizes.medium
                            ? 'sizeButton sizeButtonSelected'
                            : 'sizeButton'
                    }
                    onClick={() => setDimensions(selectionSizes.medium)}
                >
                    3600X2400
                </button>
                {/* Street easy download only for nyc listings */}
                {salesRegionId === 6 ? (
                    <button
                        className={
                            dimensions === selectionSizes.streetEasy
                                ? 'sizeButton sizeButtonSelected'
                                : 'sizeButton'
                        }
                        onClick={() => setDimensions(selectionSizes.streetEasy)}
                    >
                        Street Easy
                    </button>
                ) : (
                    ''
                )}
                <button
                    className={
                        dimensions === '' ? 'sizeButton sizeButtonSelected' : 'sizeButton'
                    }
                    onClick={() => setDimensions('')}
                >
                    Original
                </button>
            </div>
            <div className="submitButtonHolder">
                <button
                    className="actionButton cancel"
                    onClick={() => {
                        exitDownload();
                        googleAnalyticsAction(
                            'Cancel',
                            'Click',
                            `${
                                entityType === 'listing'
                                    ? 'Listing Edit'
                                    : 'Development Edit'
                            }: Image(s) download cancel clicked, Image type: ${currentImageType}`,
                        );
                        googleAnalyticsAction(
                            'Cancel',
                            'Click',
                            `${
                                entityType === 'listing'
                                    ? 'Listing Edit'
                                    : 'Development Edit'
                            }: Image(s) download cancel clicked, Image type: ${currentImageType}, Office: ${office}`,
                        );
                    }}
                >
                    Cancel
                </button>
                <button className="actionButton submitEnabled" onClick={downloadHandler}>
                    Download
                </button>
            </div>
        </div>
    );
};

export default Download;
