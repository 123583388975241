import { AppThunk } from '../../../../../../app/store';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { updateMainListingData } from '../../../listingSlice';
import { updateTour } from '../../../../../../shared/api/virtualTour/virtualTourApi';
import { VirtualTourUrls } from '../../../../../../shared/models/listing/virtualTourModels';

export const saveVirtualTour =
    (id: string, request: VirtualTourUrls): AppThunk =>
    async (dispatch) => {
        try {
            if (request) {
                const response = await updateTour(id, request);
                if (response) {
                    dispatch(setSuccess('Virtual Tour Url updated successfully'));
                    dispatch(updateMainListingData(request));
                }
            }
        } catch {
            Logger.error(`Error saving Virtual Tour: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving Virtual Tour'));
        }
    };
