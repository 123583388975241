import MainPhotos from './components/mainPhotos/MainPhotos';
import FloorPlan from './components/floorPlan/FloorPlan';
import ShowcaseHeroImages from './components/showcaseHeroImages/ShowcaseHeroImages';
import UploadedImages from './components/uploadedImages/UploadedImages';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    PhotoEditorState,
    AddImage,
    TotalImageCount,
    ShowcaseImageSaveRequest,
    UpdateShowcaseImageRequest,
    DeleteData,
} from './photoEditorModels';
import {
    saveImages,
    deleteImages,
    getPhotoCredits,
    savePhotoCredits,
    saveImagesPackage,
    toggleSuppressPhotoImport,
    saveReorderImage,
    getTotalImageCount,
    shareImages,
    saveCaptionDetails,
    getImageTypes,
    getFilter,
    getTags,
    saveTags,
    saveMovedImages,
    updateShowcaseImage,
    getPhotographerByListing,
} from './photoEditorApi';
import { AppThunk, RootState } from '../../../../../../app/store';
import {
    addLoadingEvent,
    removeLoadingEvent,
} from '../../../../../../shared/slices/loader/loaderSlice';
import { getImages } from '../../../../../../shared/api/images/sharedApi';
import {
    ImageData,
    ImageUrls,
    ImageDetail,
    UpdateFilter,
    TagSave,
    UpdateCaptionDetails,
    PackageProps,
    CaptionDetails,
    ShareDetails,
    PhotoCredit,
    Filter,
    ImageUpdate,
    ImageTypes,
    OrderNoUpdate,
} from '../../../../../../shared/models/images/sharedModel';
import { PhotoEditorEvents } from '../../../../../../shared/models/loader/loaderModels';
import {
    setError,
    setSuccess,
} from '../../../../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../../../../utils/logging/logger';
import { CustomKey } from '../../../listingModels';
import { updateMainListingData } from '../../../listingSlice';
import { packageAuthHeader } from '../../../../../../utils/api/apiWrapper';
import { jsonToFormData } from '../../../../../../utils/urlUtils';
import { UpdateFlag } from '../../../../../../shared/models/flag/flagModel';
import { typesInitialState } from './photoEditorConstant';
import {
    getActiveTabListing,
    updateActiveTabListingList,
} from '../../../../listingListSlice';
import { imageTypes } from '../../../../../../shared/constants/imageTypes';
import loDash from 'lodash';
import {
    toDataURL,
    getCurrentImageType,
    getStreetAddress,
} from '../../../../../../shared/utils/photos/photoUtils';
import { imageSizes } from '../../../../../../shared/constants/photos/photoConstants';

export const photoEditorForms = {
    mainPhotos: 'MainPhotos',
    floorPlan: 'FloorPlan',
    showcaseHeroImages: 'ShowcaseHeroImages',
    photographerUploadedImages: 'UploadedImages',
};
export const photoEditorFormMap = new Map([
    [photoEditorForms.mainPhotos, MainPhotos],
    [photoEditorForms.floorPlan, FloorPlan],
    [photoEditorForms.showcaseHeroImages, ShowcaseHeroImages],
    [photoEditorForms.photographerUploadedImages, UploadedImages],
]);

const initialState: PhotoEditorState = {
    allImages: {
        photos: [],
        floorplans: [],
        vanity: [],
        photographers: [],
    },
    photoCredit: {
        credit: '',
        suppressPhotoImport: false,
    },
    allImagesFlags: {
        photos: false,
        floorplans: false,
        vanity: false,
        photographers: false,
    },
    currentOrderNo: {
        photos: 0,
        floorplans: 0,
        vanity: 0,
        photographers: 0,
    },
    imagesCount: {
        photos: 0,
        floorplans: 0,
        vanity: 0,
        photographers: 0,
    },
    flags: {
        isUploading: false,
        imageCountFlag: false,
        creditsFlag: false,
        sharing: false,
        savingCrop: false,
        imageTypesFlag: false,
        downloading: false,
        filterFlag: false,
        savingShowcaseImages: false,
        sendToMainPhotos: false,
        selectionFromMainPhoto: false,
        photographers: false,
        sendToFP: false,
    },
    types: typesInitialState,
    filter: [],
    imageTags: [],
    photographers: [],
};

const buildImageUrl = (
    newImage: ImageData,
    request: ImageDetail,
    assetType = 'photos',
): string => {
    let ext = '.jpg';
    if (request.mimeType === 'image/png') {
        ext = '.png';
    }
    let url = `${process.env.REACT_APP_PHOTOURL}/${assetType}/${newImage.guid}_${
        newImage.islandscape ? imageSizes.mediumLandscape : imageSizes.mediumPortrait
    }${ext}`;
    if (request.mimeType === 'application/pdf') {
        url = newImage.urls.mediumUrl;
    }
    return url;
};

const photoSlice = createSlice({
    name: 'photoDetails',
    initialState: initialState,
    reducers: {
        updateAllImages: (state, action: PayloadAction<ImageUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = action.payload.data;
            if (state.allImages[property].length > 0) {
                state.currentOrderNo[property] =
                    state.allImages[property][
                        state.allImages[property].length - 1
                    ].orderNo;
            }
        },
        updateAllImagesFlag: (state, action: PayloadAction<string>) => {
            state.allImagesFlags[
                action.payload as keyof typeof initialState.allImagesFlags
            ] = true;
        },
        deleteImagesData: (state, action: PayloadAction<DeleteData>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = state.allImages[property].filter(
                (image: ImageData) => {
                    return action.payload.ids.find((id: string) => id === image.guid)
                        ? false
                        : true;
                },
            );
            state.allImages[property] = state.allImages[property].map(
                (image: ImageData, index) => {
                    return { ...image, orderNo: index + 1 };
                },
            );
            state.currentOrderNo[property] = state.allImages[property].length;
        },
        updateOrderNo: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.currentOrderNo;
            state.currentOrderNo[property] = action.payload.data;
        },
        updatePhotoCredits: (state, action: PayloadAction<PhotoCredit>) => {
            state.photoCredit = action.payload;
        },
        updateFlag: (state, action: PayloadAction<UpdateFlag>) => {
            state.flags[action.payload.property as keyof typeof initialState.flags] =
                action.payload.value;
        },
        addImages: (state, action: PayloadAction<AddImage>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.allImages[property] = [
                ...state.allImages[property],
                action.payload.data,
            ];
        },
        updateTotalImageCount: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.imagesCount;
            state.imagesCount[property] = action.payload.data;
        },
        updateCaption: (state, action: PayloadAction<UpdateCaptionDetails>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;

            state.allImages[property] = state.allImages[property].map((item) =>
                item.guid === action.payload.id
                    ? action.payload.isFloorPlanCaption
                        ? { ...item, floorplancaption: action.payload.caption }
                        : { ...item, caption: action.payload.caption }
                    : item,
            );
        },
        updateImageDataByOrderNo: (state, action: PayloadAction<AddImage>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;

            state.allImages[property] = state.allImages[property].map((item) =>
                item.orderNo === action.payload.data.orderNo
                    ? { ...item, ...action.payload.data }
                    : item,
            );
        },
        loadImageTypes: (state, action: PayloadAction<ImageTypes[]>) => {
            action.payload.forEach((item) => {
                const property = getCurrentImageType(
                    item.alias,
                ) as keyof typeof state.types;
                state.types[property] = item;
            });
        },
        updateFilterTags: (state, action: PayloadAction<Filter[]>) => {
            state.filter = [...action.payload];
        },
        loadImageTags: (state, action: PayloadAction<Filter[]>) => {
            state.imageTags = [...action.payload];
        },
        updateImageTags: (state, action: PayloadAction<TagSave>) => {
            state.imageTags = state.imageTags.map((item) => {
                return item.isSelectable
                    ? action.payload.ids.find((id) => id === parseInt(item.id))
                        ? { ...item, isSelected: true }
                        : { ...item, isSelected: false }
                    : item;
            });
        },
        updatePhotosCountInType: (state, action: PayloadAction<OrderNoUpdate>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;
            state.types[property] = {
                ...state.types[property],
                photosLoaded: state.types[property].photosLoaded + action.payload.data,
            };
        },
        updateFilterTag: (state, action: PayloadAction<UpdateFilter>) => {
            const property = action.payload
                .imageType as keyof typeof initialState.allImages;

            state.allImages[property] = state.allImages[property].map((item) =>
                item.guid === action.payload.image.guid
                    ? action.payload.tagData
                        ? {
                              ...item,
                              tagName: action.payload.tagData.name,
                              tagId: parseInt(action.payload.tagData.id),
                          }
                        : {
                              ...item,
                              tagName: null,
                              tagId: null,
                          }
                    : item,
            );
        },
        resetPhotoState: (state) => {
            return {
                ...state,
                ...initialState,
            };
        },
        updatePhotographers: (state, action: PayloadAction<string[]>) => {
            return {
                ...state,
                photographers: [...action.payload],
            };
        },
    },
});

export const fetchImages =
    (entityType: string, entityId: string, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_GET));
            let response = await getImages(
                entityType,
                entityId,
                getCurrentImageType(imageType),
            );
            if (response) {
                response = loDash.sortBy(response, 'orderNo');
                const imagesUpdateData: ImageUpdate = {
                    data: response,
                    imageType: imageType,
                };
                const uniqueImages = loDash.uniqBy(response, 'orderNo');
                if (!loDash.isEqual(uniqueImages, response)) {
                    dispatch(
                        setError(
                            'Duplicate image order number(s) are detected, please contact App Support to fix this issue.',
                        ),
                    );
                }
                dispatch(updateAllImages(imagesUpdateData));
                dispatch(updateAllImagesFlag(imageType));
            }
        } catch (e) {
            Logger.error('Failed to fetch Photos');
            dispatch(setError(`Failed to fetch Photos`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_GET));
        }
    };

export const saveImageDetails =
    (request: ImageDetail, oldImage?: ImageData): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            const currentListingImages = getState().listing.listing.data.images;
            dispatch(
                updateFlag({
                    property: oldImage ? 'savingCrop' : 'isUploading',
                    value: true,
                }),
            );
            const data = jsonToFormData({
                ...request,
                imageType: getCurrentImageType(request.imageType),
            });
            const response = await saveImages(data);
            if (response) {
                const newImage: ImageData = {
                    caption: oldImage ? oldImage.caption : null,
                    entityId: request.entityId,
                    entityType: request.entityType,
                    floorplancaption: oldImage ? oldImage.floorplancaption : null,
                    guid: request.id,
                    isPublished: oldImage ? oldImage.isPublished : true,
                    isVanity: oldImage ? oldImage.isVanity : false,
                    lastModified: '',
                    orderNo: request.orderNo,
                    size: {
                        width: request.width,
                        height: request.height,
                        sizeKb: request.size,
                    },
                    type: request.mimeType,
                    urls: response as ImageUrls,
                    islandscape: request.width > request.height ? true : false,
                    tagId: oldImage ? oldImage.tagId : null,
                    tagName: oldImage ? oldImage.tagName : null,
                };
                if (oldImage) {
                    const deleteData: DeleteData = {
                        ids: [oldImage.guid],
                        shouldValidate: false,
                        imageType: getCurrentImageType(request.imageType),
                    };

                    await deleteImages(deleteData);
                    dispatch(
                        updateImageDataByOrderNo({
                            data: newImage,
                            imageType: request.imageType,
                        }),
                    );
                    dispatch(setSuccess(`Image Cropped and saved successfully`));
                } else {
                    if (response.entityIsValid !== null) {
                        const mainStateUpdate: CustomKey = {
                            isValid: response.entityIsValid || false,
                        };
                        dispatch(updateMainListingData(mainStateUpdate));
                    }

                    dispatch(addImages({ data: newImage, imageType: request.imageType }));
                    dispatch(
                        updatePhotosCountInType({
                            data: 1,
                            imageType: request.imageType,
                        }),
                    );
                    dispatch(setSuccess('Photos saved successfully'));
                }
                //update main listing and list state for both new and old image update
                /* let ext = '.jpg';
                if (request.mimeType === 'image/png') {
                    ext = '.png';
                } */
                if (request.imageType === imageTypes.MainPhotos) {
                    const imageObject = {
                        imageUrl: buildImageUrl(newImage, request),
                        isLandscape: newImage.islandscape,
                        orderNo: newImage.orderNo,
                        isSmall: false,
                    };
                    let updatedImageList = [...currentListingImages];
                    if (oldImage) {
                        const foundIndex = updatedImageList.findIndex(
                            (x) => x.orderNo === oldImage.orderNo,
                        );
                        updatedImageList[foundIndex] = imageObject;
                    } else {
                        updatedImageList = [
                            ...updatedImageList,
                            {
                                imageUrl: buildImageUrl(newImage, request),
                                isLandscape: newImage.islandscape,
                                orderNo: newImage.orderNo,
                                isSmall: false,
                            },
                        ];
                    }
                    const listingPhotosUpdate: CustomKey = {
                        images: loDash.sortBy(updatedImageList, ['orderNo']),
                    };
                    dispatch(updateMainListingData(listingPhotosUpdate));
                    dispatch(
                        updateActiveTabListingList({
                            listingId: request.entityId,
                            listingSearchImageResult: {
                                imageUrl: updatedImageList[0]?.imageUrl || '',
                                isLandscape: updatedImageList[0]?.isLandscape || false,
                            },
                        }),
                    );
                }
                if (request.imageType === imageTypes.FloorPlan) {
                    const currentFPImages =
                        getState().listing.listing.data.floorplanImages;
                    const imageObject = {
                        imageUrl: buildImageUrl(newImage, request, 'floorplans'),
                        isLandscape: newImage.islandscape,
                        orderNo: newImage.orderNo,
                        isSmall: false,
                    };
                    let updatedImageList = [...currentFPImages];
                    if (oldImage) {
                        const foundIndex = updatedImageList.findIndex(
                            (x) => x.orderNo === oldImage.orderNo,
                        );
                        updatedImageList[foundIndex] = imageObject;
                    } else {
                        updatedImageList = [
                            ...updatedImageList,
                            {
                                imageUrl: buildImageUrl(newImage, request, 'floorplans'),
                                isLandscape: newImage.islandscape,
                                orderNo: newImage.orderNo,
                                isSmall: false,
                            },
                        ];
                    }
                    const listingFPPhotosUpdate: CustomKey = {
                        floorplanImages: loDash.sortBy(updatedImageList, ['orderNo']),
                    };
                    dispatch(updateMainListingData(listingFPPhotosUpdate));
                }
            }
        } catch (e) {
            Logger.error(`Error saving photos for: ${request.id}`);
            dispatch(
                setError(oldImage ? 'Error saving cropped image' : 'Error saving Photos'),
            );
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            dispatch(
                updateFlag({
                    property: oldImage ? 'savingCrop' : 'isUploading',
                    value: false,
                }),
            );
        }
    };

export const deleteImageDetails =
    (deleteData: DeleteData): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
            const currentListingImages = getState().listing.listing.data.images;
            const response = await deleteImages({
                ...deleteData,
                imageType: getCurrentImageType(deleteData.imageType),
            });
            if (response.entityIsValid !== null) {
                const mainStateUpdate: CustomKey = {
                    isValid: response.entityIsValid || false,
                };
                dispatch(updateMainListingData(mainStateUpdate));
            }
            if (deleteData.imageType === imageTypes.MainPhotos) {
                const listingId = getState().listing.listing.data.id;
                const updatedImageList = currentListingImages
                    .filter(
                        (im) => !deleteData.ids.find((id) => im.imageUrl.includes(id)),
                    )
                    .map((img, i) => {
                        return { ...img, orderNo: i + 1 };
                    });

                const listingPhotosUpdate: CustomKey = {
                    images: updatedImageList,
                };
                dispatch(updateMainListingData(listingPhotosUpdate));
                dispatch(
                    updateActiveTabListingList({
                        listingId: listingId,
                        listingSearchImageResult: {
                            imageUrl: updatedImageList[0]?.imageUrl || '',
                            isLandscape: updatedImageList[0]?.isLandscape || false,
                        },
                    }),
                );
            }
            if (deleteData.imageType === imageTypes.FloorPlan) {
                const currentPLImages = getState().listing.listing.data.floorplanImages;
                const updatedImageList = currentPLImages
                    .filter(
                        (im) => !deleteData.ids.find((id) => im.imageUrl.includes(id)),
                    )
                    .map((img, i) => {
                        return { ...img, orderNo: i + 1 };
                    });

                const listingFPPhotosUpdate: CustomKey = {
                    floorplanImages: updatedImageList,
                };
                dispatch(updateMainListingData(listingFPPhotosUpdate));
            }
            dispatch(deleteImagesData(deleteData));
            dispatch(
                updatePhotosCountInType({
                    data: -deleteData.ids.length,
                    imageType: deleteData.imageType,
                }),
            );
            dispatch(setSuccess(`Images deleted successfully`));
        } catch (e) {
            dispatch(setError(`Failed To Delete Images`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_DELETE));
        }
    };

export const fetchPhotoCredits =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getPhotoCredits(id);
            if (response) {
                dispatch(updatePhotoCredits(response));
                dispatch(updateFlag({ property: 'creditsFlag', value: true }));
            }
        } catch (e) {
            Logger.error('Failed to fetch Photo Credits');
            dispatch(setError(`Failed to fetch Photo Credits`));
        }
    };

export const savePhotoCredit =
    (id: string, request: PhotoCredit): AppThunk =>
    async (dispatch) => {
        try {
            if (request) {
                const response = await savePhotoCredits(id, request);
                if (response.status !== 204) {
                    throw Error('Error saving Photo Credit details');
                } else {
                    dispatch(updatePhotoCredits(request));
                    dispatch(setSuccess('Photo Credit details updated successfully'));
                }
            }
        } catch (e) {
            Logger.error(`Error saving Photo Credits: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving Photo Credits'));
        }
    };

export const updateSuppressPhotoImport =
    (id: string, request: PhotoCredit): AppThunk =>
    async (dispatch) => {
        try {
            const response = await toggleSuppressPhotoImport(id);
            if (response.status !== 204) {
                throw Error('Error saving MLS photo import flag');
            } else {
                dispatch(updatePhotoCredits(request));
                dispatch(setSuccess('MLS photo import updated successfully'));
                //to update listing in the listing list section
                dispatch(getActiveTabListing(id, 'suppressPhotoImport'));
            }
        } catch (e) {
            Logger.error(`Error saving MLS Photo Import: ${JSON.stringify(request)}`);
            dispatch(setError('Error saving MLS Photo Import flag'));
        }
    };

function handleDownload(response: string) {
    const downloadUrl = `${process.env.REACT_APP_PHOTOURL}/${response}`;
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = `${response}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

const ifPackageExists = async (
    response: string,
    isDownload: boolean,
): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
        let count = 0;
        const existUrl = `${process.env.REACT_APP_PHOTOURL}/api/asset/exists?key=${response}`;
        const handleError = function (intervalId: NodeJS.Timeout, res: Response) {
            if (++count === 36) {
                window.clearInterval(intervalId);
                Promise.reject(`Error zipping images ${res}`);
                reject(res.status);
            }
        };
        const intervalId = setInterval(function () {
            fetch(existUrl + `&ts=${Date.now()}`, { headers: packageAuthHeader }).then(
                function (res) {
                    if (res.status !== 200) {
                        handleError(intervalId, res);
                    } else {
                        window.clearInterval(intervalId);
                        if (isDownload) {
                            handleDownload(response);
                        }
                        Promise.resolve();
                        resolve(res.status);
                    }
                },
                function (res) {
                    handleError(intervalId, res);
                },
            );
        }, 5000);
    });
};

export const saveImageSharePackage =
    (url: string, request: PackageProps, shareData: ShareDetails): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request && shareData) {
                dispatch(updateFlag({ property: 'sharing', value: true }));
                const { rfgId, id } = getState().listing.listing.data;
                const requestData = {
                    ...request,
                    fileName: rfgId ? rfgId : id,
                };
                const response = await saveImagesPackage(url, requestData);
                if (response.length > 0) {
                    const res = await ifPackageExists(response, false);
                    if (res === 200) {
                        const shareUrl = `${process.env.REACT_APP_PHOTOURL}/${response}`;
                        const shareResponse = await shareImages({
                            ...shareData,
                            url: shareUrl,
                        });
                        if (shareResponse.status === 200) {
                            dispatch(setSuccess('Images shared successfully'));
                        }
                    }
                } else {
                    throw Error('Error zipping images');
                }
                dispatch(updateFlag({ property: 'sharing', value: false }));
            }
        } catch (e) {
            Logger.error(`Error zipping images`);
            dispatch(setError('Error zipping images'));
            dispatch(updateFlag({ property: 'sharing', value: false }));
        }
    };

export const savePhotoReorder =
    (id: string, data: ImageData[]): AppThunk =>
    async (dispatch) => {
        try {
            const finalData = data.map((data) => {
                return {
                    id: data.guid,
                    orderNo: data.orderNo,
                };
            });
            if (finalData.length) {
                await saveReorderImage(id, finalData);
                dispatch(setSuccess('Image orders have been updated'));
            }
        } catch (e) {
            Logger.error(`Failed to save image reorder: ${e}`);
            dispatch(setError(`Failed to save image reorder`));
        }
    };

export const fetchTotalImagesCount =
    (entityId: string, entityType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getTotalImageCount(entityId, entityType);
            if (response) {
                response.forEach((item: TotalImageCount) => {
                    const totalCount: OrderNoUpdate = {
                        imageType: getCurrentImageType(item.imageType.alias),
                        data: item.count,
                    };
                    dispatch(updateTotalImageCount(totalCount));
                });
                dispatch(updateFlag({ property: 'imageCountFlag', value: true }));
            }
        } catch (e) {
            Logger.error('Failed to Fetch Total Images Count');
            dispatch(setError(`Failed to Fetch Total Images Count`));
        }
    };

export const saveImageCaption =
    (id: string, data: CaptionDetails, imageType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await saveCaptionDetails(id, data);
            if (response.status === 204) {
                const toUpdate: UpdateCaptionDetails = {
                    id: id,
                    imageType: imageType,
                    caption: data.caption,
                    isFloorPlanCaption: data.isFloorPlanCaption,
                };
                dispatch(updateCaption(toUpdate));
                !data.isFloorPlanCaption
                    ? dispatch(setSuccess('Caption Updated'))
                    : dispatch(setSuccess('Floor Plan Title Updated'));
            }
        } catch (e) {
            Logger.error(`Failed to save image caption: ${e}`);
            dispatch(setError(`Failed to save image caption`));
        }
    };

export const fetchImageTypes =
    (entityId: string, entityType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getImageTypes(entityId, entityType);
            if (response) {
                dispatch(loadImageTypes(response));
                dispatch(updateFlag({ property: 'imageTypesFlag', value: true }));
            }
        } catch (e) {
            Logger.error('Failed to fetch Image Types');
            dispatch(setError(`Failed to fetch Image Types`));
        }
    };

export const downloadImagePackage =
    (url: string, request: PackageProps): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(updateFlag({ property: 'downloading', value: true }));
            const { rfgId, address1, address2 } = getState().listing.listing.data;
            const requestData = {
                ...request,
                fileName: getStreetAddress(rfgId, address1, address2),
            };
            const response = await saveImagesPackage(url, requestData);
            if (response.length > 0) {
                const res = await ifPackageExists(response, true);
                if (res === 200) {
                    dispatch(setSuccess('Starting to download'));
                }
            }
            dispatch(updateFlag({ property: 'downloading', value: false }));
        } catch (e) {
            Logger.error(`Failed to download images: ${e}`);
            dispatch(setError(`Failed to download images`));
            dispatch(updateFlag({ property: 'downloading', value: false }));
        }
    };

export const imageDownload =
    (url: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const a = document.createElement('a');
            a.href = await toDataURL(url);
            const { rfgId, address1, address2 } = getState().listing.listing.data;
            a.download = `${getStreetAddress(rfgId, address1, address2)}.${
                url.split('.')[url.split('.').length - 1]
            }`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            dispatch(setSuccess(`${url.includes('pdf') ? 'File' : 'Image'} downloading`));
        } catch (e) {
            Logger.error('Failed to download Image');
            dispatch(setError(`Failed to download Image`));
        }
    };

export const fetchFilter = (): AppThunk => async (dispatch) => {
    try {
        const response = await getFilter();
        if (response) {
            dispatch(updateFilterTags(response));
            dispatch(updateFlag({ property: 'filterFlag', value: true }));
        }
    } catch (e) {
        Logger.error('Failed to fetch Filter details');
        dispatch(setError(`Failed to fetch Filter details`));
    }
};

export const fetchImageTags =
    (entityId: string, imageId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getTags(entityId, imageId);
            if (response) {
                dispatch(loadImageTags(response));
            }
        } catch (e) {
            Logger.error('Failed to fetch Image tags');
            dispatch(setError(`Failed to fetch Image tags`));
        }
    };

export const saveImageTags =
    (
        image: ImageData,
        chipData: Filter | undefined,
        currentImageType: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            const requestData: TagSave = {
                ids: chipData ? [parseInt(chipData.id)] : [],
            };
            const response = await saveTags(image.entityId, image.guid, requestData);
            if (response.status !== 200) {
                throw Error('Error saving image tags');
            } else {
                dispatch(updateImageTags(requestData));
                dispatch(
                    updateFilterTag({
                        image: image,
                        tagData: chipData,
                        imageType: currentImageType,
                    }),
                );
                dispatch(setSuccess('Image tags saved successfully'));
            }
        } catch (e) {
            Logger.error(`Error saving image tags: ${JSON.stringify(chipData)}`);
            dispatch(setError('Error saving image tags'));
        }
    };

export const moveImages =
    (
        showcaseImage: ShowcaseImageSaveRequest,
        selectedImageList: ImageData,
        activeOrderNo: number,
        flagValue: string,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(addLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            dispatch(
                updateFlag({
                    property: flagValue,
                    value: true,
                }),
            );
            const response = await saveMovedImages(showcaseImage);
            if (response !== null) {
                const updateShowcase: UpdateShowcaseImageRequest = {
                    sourceGuid: selectedImageList.guid,
                    newGuid: response.newId,
                };
                await updateShowcaseImage(
                    updateShowcase,
                    selectedImageList.entityId,
                    selectedImageList.entityType,
                    showcaseImage.newImageType,
                );
                const selectedImage: ImageData = {
                    ...selectedImageList,
                    guid: response.newId,
                    isVanity: flagValue === 'savingShowcaseImages',
                    orderNo: activeOrderNo,
                };
                dispatch(
                    addImages({
                        data: selectedImage,
                        imageType: showcaseImage.newImageType,
                    }),
                );
                dispatch(
                    updatePhotosCountInType({
                        data: 1,
                        imageType: showcaseImage.newImageType,
                    }),
                );
                dispatch(
                    updateOrderNo({
                        imageType: showcaseImage.newImageType,
                        data: activeOrderNo,
                    }),
                );
                dispatch(setSuccess(`Successfully moved images`));
            }
        } catch (exception) {
            Logger.error(`Failed to move images ${exception}`);
            dispatch(setError(`Failed to move images`));
        } finally {
            dispatch(removeLoadingEvent(PhotoEditorEvents.PHOTOS_SAVE));
            dispatch(
                updateFlag({
                    property: flagValue,
                    value: false,
                }),
            );
        }
    };

export const fetchPhotographers =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getPhotographerByListing(id);
            if (response) {
                dispatch(updatePhotographers(response));
            }
        } catch (e) {
            Logger.error('Failed to fetch Photographers');
            dispatch(setError(`Failed to fetch Photographers`));
        } finally {
            dispatch(updateFlag({ property: 'photographers', value: true }));
        }
    };

export const {
    updateAllImages,
    deleteImagesData,
    updatePhotoCredits,
    updateAllImagesFlag,
    updateOrderNo,
    addImages,
    updateTotalImageCount,
    updateFlag,
    updateCaption,
    updateImageDataByOrderNo,
    loadImageTypes,
    updateFilterTags,
    loadImageTags,
    updateImageTags,
    updatePhotosCountInType,
    updateFilterTag,
    resetPhotoState,
    updatePhotographers,
} = photoSlice.actions;
export const photoDetails = (state: RootState): PhotoEditorState => state.listing.photos;

export default photoSlice.reducer;
