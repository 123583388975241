import { FunctionComponent, useEffect, useState } from 'react';
import { SectionProps, ShowcaseImageSaveRequest } from '../../photoEditorModels';
import {
    fetchPhotographers,
    photoDetails,
    moveImages,
    deleteImageDetails,
    updateAllImages,
    saveImageSharePackage,
    downloadImagePackage,
    imageDownload,
    fetchImageTags,
    updateFlag,
    saveImageDetails,
    saveImageCaption,
    saveImageTags,
} from '../../photoEditorSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../../app/hooks';
import Invite from './invitationForm/Invite';
import Invited from './invitedPhotographers/Invited';
import EditorSection from '../../../../../../../../shared/component/photos/editor/EditorSection';
import {
    ImageData,
    ImageUpdate,
} from '../../../../../../../../shared/models/images/sharedModel';
import { imageTypes } from '../../../../../../../../shared/constants/imageTypes';
import { getCurrentImageType } from '../../../../../../../../shared/utils/photos/photoUtils';
import { UpdateFlag } from '../../../../../../../../shared/models/flag/flagModel';
import { setCurrentFormData, listingDetail } from '../../../../../listingSlice';
import { setError } from '../../../../../../../../shared/slices/messaging/messagingSlice';

const UploadedImages: FunctionComponent<SectionProps> = ({ data, currentSection }) => {
    const { photographers, flags, currentOrderNo, types, allImages, filter, imageTags } =
        useAppSelector(photoDetails);
    const {
        data: { salesRegionId },
    } = useAppSelector(listingDetail);
    const [imageSelectCount, setImageSelectCount] = useState<number>(0);
    const [uploadCount, setUploadCount] = useState<number>(1);
    const [totalUploadCount, setTotalUploadCount] = useState<number>(0);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!flags.photographers) {
            dispatch(fetchPhotographers(data.editFormData.data.id));
        }
    }, [dispatch, flags.photographers, data.editFormData.data.id]);

    const updateImages = (imageData: ImageUpdate) => {
        dispatch(updateAllImages(imageData));
    };

    const flagUpdate = (data: UpdateFlag) => {
        dispatch(updateFlag(data));
    };

    const setFormData = (images: ImageData[], formName: string) => {
        dispatch(
            setCurrentFormData({
                formData: images,
                listingEditFormName: formName,
            }),
        );
    };

    let activeOrderNo = currentOrderNo.photos;

    const maxAllowedPhotos =
        parseInt(types.photos.maxPhotos) -
        (types.photos.photosLoaded + types.floorplans.photosLoaded);

    const saveMainPhotos = async (
        selectedImagesList: ImageData[],
        newImageType: string,
    ) => {
        const count =
            selectedImagesList.length <= maxAllowedPhotos
                ? selectedImagesList.length
                : maxAllowedPhotos;
        setTotalUploadCount(count);
        if (count < 1) {
            dispatch(
                setError('Selected images count exceeds total number of allowed photos'),
            );
        }
        for (let i = 0; i < count; i++) {
            activeOrderNo++;
            const showcaseImage: ShowcaseImageSaveRequest = {
                x: 0,
                y: 0,
                width: selectedImagesList[i].size.width,
                height: selectedImagesList[i].size.height,
                newImageType: newImageType,
                sourceKey: `${getCurrentImageType(imageTypes.UploadedImages)}/${
                    selectedImagesList[i].guid
                }.jpg`,
            };
            dispatch(
                moveImages(
                    showcaseImage,
                    selectedImagesList[i],
                    activeOrderNo,
                    newImageType === imageTypes.MainPhotos
                        ? 'sendToMainPhotos'
                        : 'sendToFP',
                ),
            );
            setImageSelectCount(0);
            setUploadCount(i + 2);
        }
    };

    return (
        <>
            <Invite data={data} photographers={photographers} />
            <Invited photographers={photographers} />
            <EditorSection
                from="listing"
                currentImageType={imageTypes.UploadedImages}
                currentImageDetails={types.photographers}
                images={allImages.photographers}
                entityId={data.editFormData.data.id}
                filter={filter}
                flags={flags}
                deleteImageDetails={deleteImageDetails}
                updateImages={updateImages}
                saveImageSharePackage={saveImageSharePackage}
                downloadImagePackage={downloadImagePackage}
                imageDownload={imageDownload}
                fetchImageTags={fetchImageTags}
                flagUpdate={flagUpdate}
                saveImageDetails={saveImageDetails}
                saveImageCaption={saveImageCaption}
                imageTags={imageTags}
                saveImageTags={saveImageTags}
                setFormData={setFormData}
                currentSection={currentSection}
                saveMainPhotos={saveMainPhotos}
                imageSelectCount={imageSelectCount}
                setImageSelectCount={setImageSelectCount}
                uploadCount={uploadCount}
                setUploadCount={setUploadCount}
                totalUploadCount={totalUploadCount}
                entityType="listing"
                salesRegionId={salesRegionId}
                office={data.editFormData.data.office}
            />
        </>
    );
};
export default UploadedImages;
