import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { PaginationResponse } from '../../../shared/models/pagination/paginationModels';
import {
    ListingsListState,
    ListingItem,
    ListingSearchRequest,
    ListingTableActionType,
    ImageCountUpdate,
    PhotographerImageCount,
    LoaderUpdate,
} from './listingsListModels';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import {
    fetchPhotographerListings,
    fetchImagesCount,
    getListingDetail,
} from './listingsListApi';
import { VirtualTourUrls } from '../../../shared/models/listing/virtualTourModels';
import { updateTour } from '../../../shared/api/virtualTour/virtualTourApi';

const listingsListState: ListingsListState = {
    data: { currentPage: 1, totalRecords: 0, recordsPerPage: 0, results: [] },
    tableAction: {
        sortColumn: 'address1',
        sortDirection: 'asc',
        currentPage: 1,
        itemsPerPage: 20,
    },
    isLoading: true,
    imageCountsData: [],
    currentSearchData: {
        address: '',
        currentPage: 1,
        itemsPerPage: 20,
    },
    loadListing: {
        status: false,
        message: '',
    },
    listingItem: undefined,
};

export const listingsListSlice = createSlice({
    name: 'listingsList',
    initialState: listingsListState,
    reducers: {
        updateListings: (
            state,
            action: PayloadAction<PaginationResponse<ListingItem>>,
        ) => {
            let data = [...state.data.results, ...action.payload.results];
            if (state.data.currentPage >= action.payload.currentPage) {
                data = action.payload.results;
            }
            data = data.map((item) => {
                return { ...item, address: item.address1 || '' + item.address2 };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    currentPage: action.payload.currentPage,
                    totalRecords: action.payload.totalRecords,
                    recordsPerPage: action.payload.recordsPerPage,
                    results: data,
                },
            };
        },
        updateTableAction: (state, action: PayloadAction<ListingTableActionType>) => {
            const finalData = { ...state.tableAction, ...action.payload };
            return {
                ...state,
                tableAction: finalData,
            };
        },
        updateLoader: (state, action: PayloadAction<LoaderUpdate>) => {
            return action.payload.individualListing
                ? {
                      ...state,
                      loadListing: {
                          status: action.payload.value.status,
                          message: action.payload.value.message,
                      },
                      isLoading: action.payload.value.status,
                  }
                : {
                      ...state,
                      isLoading: action.payload.value.status,
                  };
        },
        updateImagesCount: (state, action: PayloadAction<ImageCountUpdate>) => {
            const updatedListings = state.data.results.map((listing) => {
                return listing.listingId === action.payload.listingId
                    ? {
                          ...listing,
                          imageCount: listing.imageCount + action.payload.imageCount,
                      }
                    : listing;
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    results: updatedListings,
                },
            };
        },
        setInitialState: (state) => {
            return {
                ...state,
                data: {
                    currentPage: 1,
                    totalRecords: 0,
                    recordsPerPage: 0,
                    results: [],
                },
                tableAction: {
                    sortColumn: 'address1',
                    sortDirection: 'asc',
                    currentPage: 1,
                    itemsPerPage: 20,
                },
                isLoading: true,
                imageCountsData: [],
                currentSearchData: {
                    address: '',
                    currentPage: 1,
                    itemsPerPage: 20,
                },
                listingItem: undefined,
            };
        },
        updatePhotographerImagesCount: (
            state,
            action: PayloadAction<PhotographerImageCount[]>,
        ) => {
            return {
                ...state,
                imageCountsData: action.payload,
            };
        },
        updateCurrentSearchData: (state, action: PayloadAction<ListingSearchRequest>) => {
            return {
                ...state,
                currentSearchData: action.payload,
            };
        },
        setListingItem: (state, action: PayloadAction<ListingItem | undefined>) => {
            return {
                ...state,
                listingItem: action.payload,
            };
        },
        updateItemInList: (state, action: PayloadAction<ListingItem | undefined>) => {
            const updatedResult = state.data.results.map((i) =>
                i.listingId === action.payload?.listingId ? action.payload : i,
            );
            return {
                ...state,
                data: { ...state.data, results: updatedResult },
            };
        },
    },
});

const updateImageCountInListing = (
    listings: PaginationResponse<ListingItem>,
    counts: PhotographerImageCount[],
) => {
    const updatedResults = listings.results.map((item) => {
        const listingToUpdate = counts.find(
            (count) => count.listingId === item.listingId,
        );
        return listingToUpdate
            ? { ...item, imageCount: listingToUpdate.imageCount }
            : item;
    });
    return { ...listings, results: updatedResults };
};

export const getPhotographerListings =
    (request: ListingSearchRequest, listingId?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(
                updateLoader({
                    individualListing: listingId ? true : false,
                    value: {
                        status: true,
                        message: 'Fetching listing',
                    },
                }),
            );
            const response = await fetchPhotographerListings(request);
            if (response) {
                let updatedResult =
                    response as unknown as PaginationResponse<ListingItem>;
                if (listingId) {
                    let listingItem = updatedResult.results.find(
                        (item) => item.listingId === listingId,
                    );
                    if (!listingItem) {
                        listingItem = await getListingDetail(listingId);
                    }
                    dispatch(setListingItem(listingItem));
                }
                if (request.officeIds) {
                    let currentImageCounts =
                        getState().photographerListings.imageCountsData;
                    if (!currentImageCounts.length) {
                        currentImageCounts = await fetchImagesCount();
                        dispatch(updatePhotographerImagesCount(currentImageCounts));
                    }
                    updatedResult = updateImageCountInListing(
                        updatedResult,
                        currentImageCounts,
                    );
                }
                dispatch(updateListings(updatedResult));
                dispatch(
                    updateLoader({
                        individualListing: listingId ? true : false,
                        value: {
                            status: false,
                            message: '',
                        },
                    }),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch Listings`));
        } finally {
            dispatch(
                updateLoader({
                    individualListing: listingId ? true : false,
                    value: {
                        status: false,
                        message: '',
                    },
                }),
            );
        }
    };

export const saveVirtualTour =
    (id: string, request: VirtualTourUrls): AppThunk =>
    async (dispatch, getState) => {
        try {
            if (request) {
                const response = await updateTour(id, request);
                if (response) {
                    const updatedResult = {
                        ...getState().photographerListings.listingItem,
                        ...request,
                    };
                    dispatch(setListingItem(updatedResult as ListingItem));
                    dispatch(updateItemInList(updatedResult as ListingItem));
                    dispatch(setSuccess('Virtual Tour Url updated successfully'));
                }
            }
        } catch {
            dispatch(setError('Error saving Virtual Tour'));
        }
    };

export const {
    updateListings,
    updateLoader,
    updateTableAction,
    updateImagesCount,
    setInitialState,
    updatePhotographerImagesCount,
    updateCurrentSearchData,
    setListingItem,
    updateItemInList,
} = listingsListSlice.actions;

export const listingsList = (state: RootState): ListingsListState =>
    state.photographerListings;

export default listingsListSlice.reducer;
