import { getCall, postCallRaw } from '../../../utils/api/apiWrapper';
import {
    ListingSearchRequest,
    PhotographerImageCount,
    ListingItem,
} from './listingsListModels';

export function fetchPhotographerListings(
    request: ListingSearchRequest,
): Promise<Response> {
    return postCallRaw<ListingSearchRequest>(
        request.officeIds ? `photographer/santafelistings` : `photographer/listings`,
        request,
        true,
    )
        .then((res) => {
            return res.json();
        })
        .catch((err) => console.error(err));
}

export function fetchImagesCount(): Promise<PhotographerImageCount[]> {
    return getCall<PhotographerImageCount[]>(`photographer/imagecount`, true);
}

export function getListingDetail(entityId: string): Promise<ListingItem> {
    return getCall(`listing/${entityId}/details`);
}

// for testing purpose. Shall be replaced by actual users
export const santafeListingUsers = [
    'daniel@nadelbachphoto.com',
    'hello@mediakingsmen.com',
    'priyanka.ravichandran@anywhere.re',
    'sharmila.baby@anywhere.re',
    'prabhat.ojha@anywhere.re',
    'Alexander.Frank@Sothebys.Realty'
];
