import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import {
    Input,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    makeStyles,
    withStyles,
    Select,
    MenuItem,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { schedulerDetails, addScehdule, updateSchedule } from '../schedulerSlice';
import { cmrDetails } from '../../../cmrSlice';
import { Schedules, AddEmail, ScheduleMarketReportProps } from '../schedulerModels';
import { getUser } from '../../../../../shared/auth/authService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faEdit, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { validateEmail } from '../../../../../utils/urlUtils';
import AddEmails from './addEmails';
import './index.scss';
import { googleAnalyticsAction } from '../../../../../shared/functions/googleAnalytics/ga';
import { ReportEmailSchedule } from '../schedulerConstants';

const LightTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#999',
        color: '#fff',
    },
}))(Tooltip);

const useStyles = makeStyles({
    textField: {
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: 4,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 22,
        paddingRight: 22,
    },
    input: {
        color: '#666666',
        fontFamily: "'MercuryTextG4Roman'",
        fontSize: 14,
    },
    radio: {
        '&$checked': {
            color: '#002349',
        },
        '&&:hover': {
            backgroundColor: 'transparent',
        },
    },
    checked: {},
});

const ScheduleMarketReport: FunctionComponent<ScheduleMarketReportProps> = ({
    closeScheduler,
    id,
    resetId,
    scrollToTop,
}) => {
    const { address, fromAddresses, agentNames, schedules, flags } =
        useAppSelector(schedulerDetails);
    const { listingId } = useAppSelector(cmrDetails);
    const dispatch = useAppDispatch();
    const classes = useStyles();

    const initialScheduleReportValues: Schedules = {
        id: '',
        listingId: listingId,
        recipients: '',
        recipientNames: '',
        fromAddress: '',
        fromName: '',
        subject: address ? 'Report for: ' + address : '',
        body: '',
        schedule: 0,
        createdBy: getUser().userName,
    };

    const [formValues, setFormValues] = useState(initialScheduleReportValues);
    const [isDisabled, setDisable] = useState<boolean>(false);
    const [isFormValid, setFormValid] = useState<boolean>(true);
    const [count, setCount] = useState(0);
    const [radioValue, setRadioValue] = useState(formValues.schedule);
    const [emails, setEmails] = useState<AddEmail[]>([]);
    const [getRecipientsList, setRecipientsList] = useState<string[]>();
    const [isSent, setSent] = useState(false);
    const [edit, setEdit] = useState(false);
    const editHandler = () => {
        setEdit(!edit);
    };

    const radioInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioValue(parseInt(event.target.value));
        setFormValues({ ...formValues, schedule: parseInt(event.target.value) });
    };

    const inputEmailHandler = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        const { value } = event.target;
        if (validateEmail(value)) {
            const email = {
                id: id,
                value: value,
            };
            const index = emails.findIndex((element) => element.id === id);
            if (index > -1) {
                const updatedEmails = emails.map((element) =>
                    element.id === id ? { ...element, value: value } : element,
                );
                setEmails([...updatedEmails]);
            } else {
                setEmails([...emails, email]);
            }
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    useEffect(() => {
        if (id.length) {
            const schedule = schedules.find((sch: Schedules) => sch.id === id);
            if (schedule) {
                setFormValues({ ...schedule });
                setRadioValue(schedule.schedule);
                setSent(false);
                if (schedule.recipients.split.length > 0) {
                    const recipientsList = schedule.recipients.split(',');
                    setCount(recipientsList.length - 1);
                    setRecipientsList(recipientsList);
                    const updatedEmails = recipientsList.map((recipient, index) => {
                        return { id: index, value: recipient };
                    });
                    setEmails([...updatedEmails]);
                }
            }
        }
    }, [id, schedules]);

    const updateFromDetails = (values: Schedules) => {
        setFormValues({
            ...values,
            fromAddress: fromAddresses[0],
            fromName: getFromNames(agentNames) || '',
            subject: 'Report for: ' + address,
        });
    };

    useEffect(() => {
        if (fromAddresses && fromAddresses.length > 0) {
            updateFromDetails(formValues);
        }
    }, [fromAddresses, address, agentNames]);

    useEffect(() => {
        if (emails.length > 0) {
            emails.map((email) => (!validateEmail(email.value) ? setDisable(true) : ''));
        }
    }, [emails]);

    useEffect(() => {
        validateForm();
    }, [formValues]);

    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'recipientNames' || name === 'fromName') {
            setFormValues({ ...formValues, [name]: value.replace(/[&]/g, '') });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const handleDropDownChange = (
        event: ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => {
        setFormValues((prevState) => {
            return { ...prevState, [event.target.name || '']: event.target.value };
        });
    };

    const getFromNames = (names: string[]) => {
        if (names && names.length > 0) {
            if (names.length > 1) {
                const fromNames =
                    names
                        .filter((name, index) =>
                            index === names.length - 1 ? false : true,
                        )
                        .join(', ') +
                    ' and ' +
                    names[names.length - 1];
                return fromNames;
            } else {
                return names.join(', ');
            }
        }
    };

    const clearForm = () => {
        setFormValues({ ...initialScheduleReportValues });
        updateFromDetails(initialScheduleReportValues);
        setEmails([]);
        setRadioValue(initialScheduleReportValues.schedule);
        setRecipientsList([]);
        setCount(0);
        resetId();
        if (edit) {
            editHandler();
        }
        setTimeout(() => {
            scrollToTop();
        }, 500);
    };

    const clickHandler = (from: string) => {
        googleAnalyticsAction('Email', 'Click', `Email Report ${from} Successfully`);
        //update < > to prevent html injection
        const updatedFormValues = {
            ...formValues,
            body: formValues.body.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
        };
        if (emails.length > 0) {
            const recipients = emails
                .map((email) => {
                    return email.value.trim();
                })
                .join(',');

            updatedFormValues.recipients = recipients;
            setFormValues(updatedFormValues);
        }
        if (getFromNames(agentNames)) {
            setFormValues(updatedFormValues);
        }
        setFormValues(updatedFormValues);
        if (id.length && formValues && formValues.schedule > 0) {
            dispatch(updateSchedule(updatedFormValues, id));
        } else {
            dispatch(addScehdule(updatedFormValues));
        }
        clearForm();
        setSent(true);
    };

    useEffect(() => {
        if (flags.emailSent) {
            closeScheduler();
        }
    }, [flags.emailSent, closeScheduler]);

    const cancelHandler = () => {
        googleAnalyticsAction('Email', 'Click', 'Email Report Menu Close');
        clearForm();
        closeScheduler();
    };

    const addEmailHandler = () => {
        if (!isDisabled) {
            setCount(count + 1);
        }
    };

    /**
     *  this function removes the created input field and also update the data from the emails
     *  list if it have removed email
     * @param id
     */
    const removeEmailHandler = (id: number) => {
        if (!isDisabled) {
            setCount(count - 1);
            if (emails.length) {
                const updatedEmailsemails = emails.filter((element) => element.id !== id);
                setEmails([...updatedEmailsemails]);
            }
        }
    };

    const validateForm = () => {
        if (formValues.fromName.trim() === '') {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };

    const renderFormLabel = (value: number, labelValue: string) => {
        return (
            <FormControlLabel
                className="formLabel"
                control={
                    <Radio
                        checked={radioValue === value}
                        onChange={radioInputHandler}
                        value={value}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': `${value}` }}
                        classes={{
                            root: classes.radio,
                            checked: classes.checked,
                        }}
                    />
                }
                label={
                    <div
                        className={radioValue === value ? 'label labelSelected' : 'label'}
                    >
                        {labelValue}
                    </div>
                }
            />
        );
    };

    return (
        <div className="scheduleMarketReportHolder" id="scheduleMarketReportHolder">
            <div className="scheduleMarketReportHeader">
                SCHEDULE A NEW MARKET REPORT:{' '}
                {address && address.length > 0 ? <span>{address}</span> : ''}
            </div>
            <div className="scheduleMarketReportContent">
                <div className="actionHolder">
                    <span className="inputTitle">RECIPIENT(s):</span>
                    <span className="inputHolder">
                        <Input
                            className="schedulerInput"
                            placeholder="Recipient Name(s)"
                            name="recipientNames"
                            value={formValues.recipientNames}
                            inputProps={{
                                'aria-label': 'recipientNames',
                                maxLength: 4000,
                            }}
                            onChange={inputHandler}
                            disableUnderline={true}
                        />
                    </span>
                    <div className="sh-icons info">
                        <LightTooltip
                            placement="left"
                            title={
                                <div className="customTooltipContent">
                                    <span className="text">
                                        Your recipient name(s) will be added here on the
                                        report menu:{' '}
                                    </span>

                                    <img
                                        className="tooltipImage"
                                        src="/img/cmrReportRecipientSection.png"
                                        alt=""
                                    />
                                </div>
                            }
                        >
                            <span className="icon">
                                <FontAwesomeIcon icon={faInfo} size="lg" />
                            </span>
                        </LightTooltip>
                    </div>
                </div>
                <div className="emailHolder">
                    <AddEmails
                        id={0}
                        inputEmailHandler={inputEmailHandler}
                        recipientsList={getRecipientsList}
                        isSent={isSent}
                        setSent={() => setSent(false)}
                    />
                    {[...Array(count)].map((_, index: number) => (
                        <div className="additionalInputs">
                            <AddEmails
                                key={index}
                                id={index + 1}
                                inputEmailHandler={inputEmailHandler}
                                recipientsList={getRecipientsList}
                                isSent={isSent}
                                setSent={() => setSent(false)}
                            />
                            <span
                                className={isDisabled ? 'disabledXmark xmark' : 'xmark'}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    size="lg"
                                    onClick={() => removeEmailHandler(index + 1)}
                                />
                            </span>
                        </div>
                    ))}
                    <div className="sh-icons">
                        <span className={isDisabled ? 'disabledPlus' : 'plus'}>
                            <FontAwesomeIcon
                                icon={faPlus}
                                size="lg"
                                onClick={addEmailHandler}
                            />
                        </span>
                    </div>
                </div>
                {!edit ? (
                    <div className="actionHolder">
                        <span className="inputTitle">FROM:</span>
                        <span className="inputHolder">
                            <Input
                                className="disabledInput"
                                placeholder={
                                    getFromNames(agentNames) +
                                    ' (sent from ' +
                                    formValues.fromAddress +
                                    ')'
                                }
                                name="fromName"
                                disabled={true}
                                disableUnderline={true}
                            />
                        </span>
                        <div className="edit-icon">
                            <span className="edit">
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    size="lg"
                                    onClick={editHandler}
                                />
                            </span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="actionHolder">
                            <span className="inputTitle">FROM NAME:</span>
                            <span className="inputHolder">
                                <Input
                                    className="schedulerInput"
                                    placeholder="Name"
                                    name="fromName"
                                    disabled={false}
                                    disableUnderline={true}
                                    value={formValues.fromName}
                                    onChange={inputHandler}
                                />
                            </span>
                        </div>
                        <div className="actionHolder">
                            <span className="inputTitle">FROM EMAIL:</span>
                            <span className="inputHolder">
                                <Select
                                    className={`schedulerInput schedulerDropDown ${
                                        fromAddresses.length < 2 ? 'disabledInput' : ''
                                    }`}
                                    label="Email"
                                    name="fromAddress"
                                    placeholder="Email"
                                    value={formValues.fromAddress}
                                    onChange={handleDropDownChange}
                                    inputProps={{ 'aria-label': 'Email' }}
                                    disabled={fromAddresses.length < 2}
                                    disableUnderline
                                >
                                    {fromAddresses.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </span>
                        </div>
                    </>
                )}
                <div className="actionHolder">
                    <span className="inputTitle">SUBJECT:</span>
                    <span className="inputHolder">
                        <Input
                            className="schedulerInput"
                            placeholder="Subject"
                            name="subject"
                            value={formValues.subject}
                            inputProps={{ 'aria-label': 'Buyer', maxLength: 255 }}
                            onChange={inputHandler}
                            disableUnderline={true}
                        />
                    </span>
                </div>
                <div className="actionHolder">
                    <span className="inputTitleAlt">CUSTOM MESSAGE:</span>
                    <span className="inputHolder">
                        <TextField
                            className={classes.textField}
                            multiline
                            rows={4}
                            placeholder="Enter custom message here."
                            name="body"
                            value={formValues.body}
                            onChange={inputHandler}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.input,
                            }}
                            inputProps={{ maxLength: 1000 }}
                        />
                    </span>
                </div>
                <div className="actionHolder">
                    <span className="inputTitleAlt">SCHEDULE:</span>
                    <span className="radioHolder">
                        <RadioGroup>
                            {renderFormLabel(ReportEmailSchedule.Once, 'Send Now')}
                            {renderFormLabel(
                                ReportEmailSchedule.WeeklyMonday,
                                'Weekly (sends every Monday)',
                            )}
                            {renderFormLabel(
                                ReportEmailSchedule.WeeklyFriday,
                                'Weekly (sends every Friday)',
                            )}
                            {renderFormLabel(
                                ReportEmailSchedule.SemiMonthly,
                                'Semi-Monthly (sends on the 2nd and 16th)',
                            )}
                            {renderFormLabel(
                                ReportEmailSchedule.Monthly,
                                'Monthly (sends on the 2nd)',
                            )}
                        </RadioGroup>
                    </span>
                </div>
                <div className="buttonGroup">
                    <button
                        className={
                            radioValue === 0 ||
                            isDisabled ||
                            isFormValid ||
                            emails.length === 0
                                ? 'actionButton save saveInActive'
                                : 'actionButton save'
                        }
                        disabled={
                            radioValue === 0 ||
                            isDisabled ||
                            isFormValid ||
                            emails.length === 0
                        }
                        onClick={() => clickHandler('Saved')}
                    >
                        SAVE
                    </button>
                    <button
                        className={
                            radioValue !== 0 ||
                            isDisabled ||
                            isFormValid ||
                            emails.length === 0
                                ? 'actionButton save saveInActive'
                                : 'actionButton save'
                        }
                        disabled={
                            radioValue !== 0 ||
                            isDisabled ||
                            isFormValid ||
                            emails.length === 0
                        }
                        onClick={() => clickHandler('Sent')}
                    >
                        SEND
                    </button>
                    <button className="actionButton cancel" onClick={cancelHandler}>
                        CANCEL
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ScheduleMarketReport;
