import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import { getAgentsByLastName } from '../../../shared/api/agent/agentApi';
import {
    ingestVideo,
    shareVideo,
} from '../../../shared/api/video/brightcove/brightcoveApi';
import { s3Upload } from '../../../shared/functions/video/s3Upload/s3Upload';
import {
    AgentDNDItem,
    AgentReorderRequest,
    AgentSearchResult,
} from '../../../shared/models/agent/agentModel';
import { ListingAmenity } from '../../../shared/models/listing/amenityModels';
import { setError, setSuccess } from '../../../shared/slices/messaging/messagingSlice';
import Logger from '../../../utils/logging/logger';
import {
    deleteAgentsApi,
    deleteListingsApi,
    getDevelopmentAgentsData,
    getDevelopmentAmentitiesData,
    getDevelopmentDetailsData,
    getDevelopmentListingssData,
    getDevelopmentUnits,
    postVideoMetaData,
    saveDevelopmentAmenities,
    updateAgentsApi,
    updateDevAgentsDisplayOrdersApi,
    updateDevelopmentApi,
    updateDevPublish,
    updateListingsApi,
} from './developmentDetailsApi';
import {
    initialDevelopmentDetails,
    initialDevelopmentUnits,
} from './developmentDetailsConstants';
import {
    DevelopmentAgent,
    DevelopmentCustomKey,
    DevelopmentDetailsData,
    DevelopmentDetailsResponse,
    DevelopmentDetailsState,
    DevelopmentFlagCustomKey,
    DevelopmentImageObject,
    DevelopmentListing,
    DevelopmentUnits,
    UpdateDevelopmentRequest,
} from './developmentDetailsModel';
import { videoTypes } from '../../../shared/constants/video/videoConstants';
import {
    ImageDeleteData,
    ImageDetail,
    ImageData,
} from '../../../shared/models/images/sharedModel';
import { deletePhoto, getImages, savePhoto } from '../../../shared/api/images/sharedApi';
import { entityTypes } from '../../../shared/constants/entityTypes';
import { imageTypes } from '../../../shared/constants/imageTypes';
import { jsonToFormData } from '../../../utils/urlUtils';
import { AbortController as AwsAbortController } from '@smithy/abort-controller';
import { getVideos } from '../../../shared/slices/video/videoSlice';
import { VideoModel } from '../../../shared/models/video/videoModels';
import _ from 'lodash';

const developmentDetailsInitialState: DevelopmentDetailsState = {
    data: initialDevelopmentDetails,
    isLoading: false,
    searchLoader: false,
    editForm: null,
    searchedAgents: [],
    openEditFromList: false,
    isFeaturesUpdated: false,
    flags: {
        logo: {
            isUploading: false,
            isDeleting: false,
        },
        video: {
            isUploading: false,
            isDeleting: false,
        },
    },
};

export const developmentDetailsSlice = createSlice({
    name: 'developmentListApp',
    initialState: developmentDetailsInitialState,
    reducers: {
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setDevelopmentDetails: (
            state,
            action: PayloadAction<DevelopmentDetailsResponse | UpdateDevelopmentRequest>,
        ) => {
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        },
        updateMainDevelopmentData: (
            state,
            action: PayloadAction<DevelopmentCustomKey>,
        ) => {
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        },

        setInitialState: (state) => {
            return {
                ...state,
                data: initialDevelopmentDetails,
                isLoading: false,
                editForm: null,
            };
        },
        // Sets the name of the component to open in the side drawer
        updateDevelopmentEditFormName: (
            state,
            action: PayloadAction<{
                editFormName: string;
                developmentEditFormName?: string;
            }>,
        ) => {
            state.editForm = {
                formOpen: true,
                editFormName: action.payload.editFormName,
                developmentEditFormName: action.payload.developmentEditFormName
                    ? action.payload.developmentEditFormName
                    : '',
            };
        },
        // Fired when the user wants to close the side drawer component
        closeEditForm: (state, action: PayloadAction) => {
            state.editForm = {
                formOpen: false,
                editFormName: '',
                developmentEditFormName: '',
            };
        },
        setLoader: (state, action: PayloadAction<{ status: boolean; type: string }>) => {
            return {
                ...state,
                [action.payload.type]: action.payload.status,
            };
        },
        setSearchedAgent: (state, action: PayloadAction<AgentSearchResult[]>) => {
            return {
                ...state,
                searchedAgents: action.payload,
            };
        },
        updateOpenEditFromList: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                openEditFromList: action.payload,
            };
        },
        // Sets the data currently being edited in the listing edit form open
        // in the side drawer component
        setCurrentDevelopmentEditFormData: (
            state,
            action: PayloadAction<{
                formData: DevelopmentDetailsData | ImageData[] | VideoModel;
                developmentEditFormName: string;
            }>,
        ) => {
            state.editForm = {
                editFormName: state.editForm?.editFormName || '',
                formOpen: state.editForm?.formOpen || false,
                currentFormData: action.payload.formData,
                developmentEditFormName: action.payload.developmentEditFormName,
            };
        },
        updateDevVideoStatus: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    videoDetail: {
                        ...state.data.videoDetail,
                        status: action.payload,
                    },
                },
            };
        },
        isFeatureUpdated: (state, action: PayloadAction<boolean>) => {
            state.isFeaturesUpdated = action.payload;
        },
        updateDevLogoFlagStatus: (
            state,
            action: PayloadAction<DevelopmentFlagCustomKey>,
        ) => {
            return {
                ...state,
                flags: {
                    ...state.flags,
                    ...action.payload,
                },
            };
        },
    },
});

/**
 * function will get the development details
 * @param data
 * @returns
 */
export const fetchDevelopmentDetails =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getDevelopmentDetailsData(developmentId);
            if (response.id !== '') {
                const updatedResponse = _.omit(response, ['videoDetail']);
                dispatch(
                    setDevelopmentDetails(updatedResponse as DevelopmentDetailsResponse),
                );
                dispatch(getVideos(developmentId, entityTypes.building.key));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the development details`));
            Logger.error(
                `Failed to fetch the development details ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function will get the development details
 * @param data
 * @returns
 */
export const fetchDevelopmentUnits =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await getDevelopmentUnits(developmentId);
            if (response.status === 200) {
                dispatch(
                    updateMainDevelopmentData({
                        units: (await response.json()) as unknown as DevelopmentUnits,
                    }),
                );
            }
            if (response.status === 204) {
                dispatch(
                    updateMainDevelopmentData({
                        units: initialDevelopmentUnits,
                    }),
                );
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the development units`));
            Logger.error(
                `Failed to fetch the development units ${JSON.stringify(exception)}`,
            );
        }
    };

/**
 * function will get the development agents
 * @param data
 * @returns
 */
export const fetchDevelopmentAgents =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getDevelopmentAgentsData(developmentId);
            if (response.length) {
                dispatch(updateMainDevelopmentData({ agents: response }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the development agents`));
            Logger.error(
                `Failed to fetch the development agents ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function will get the development Listings
 * @param data
 * @returns
 */
export const fetchDevelopmentListings =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getDevelopmentListingssData(developmentId);
            if (response.length) {
                dispatch(updateMainDevelopmentData({ listings: response }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the development listings`));
            Logger.error(
                `Failed to fetch the development listings ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function will get the development owners
 * @param data
 * @returns
 */
export const fetchDevelopmentAmentities =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getDevelopmentAmentitiesData(developmentId);
            if (response.length) {
                dispatch(updateMainDevelopmentData({ amenities: response }));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the development owners`));
            Logger.error(
                `Failed to fetch the development owners ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(updateLoader(false));
        }
    };

/**
 * function fetched agent based on the agent name entered in autocomplete
 * @param data
 * @returns
 */
export const fetchAgent =
    (data: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoader({ status: true, type: 'searchLoader' }));
            const response = await getAgentsByLastName(data);
            if (response.length > 0) {
                dispatch(setSearchedAgent(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the agent list`));
            Logger.error(`Failed to fetch the agent list ${JSON.stringify(exception)}`);
        } finally {
            dispatch(setLoader({ status: false, type: 'searchLoader' }));
        }
    };

/**
 * function update the development difference in DB on edit
 * @param data
 * @returns
 */
export const updateDevelopment =
    (updateRequest: UpdateDevelopmentRequest): AppThunk =>
    async (dispatch) => {
        try {
            const response = await updateDevelopmentApi(updateRequest);
            if (response.status === 204) {
                dispatch(setDevelopmentDetails(updateRequest));
                dispatch(setSuccess('Development details are updated successfully'));
            }
        } catch (exception) {
            dispatch(setError(`Error in updating the development details`));
            Logger.error(
                `Error in updating the development details ${JSON.stringify(exception)}`,
            );
        }
    };

/**
 * function add/remove agent from the list
 * @param data
 * @returns
 */
export const updateAgents =
    (developmentId: string, data?: AgentSearchResult, agentId?: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const existingAgents = getState().development.developmentDetails.data.agents;
            if (!agentId && data) {
                const response = await updateAgentsApi(
                    developmentId,
                    data as AgentSearchResult,
                );
                if (response.id) {
                    const addedAgent = [
                        {
                            id: response.id,
                            agentId: data.agentId,
                            displayOrder: data.displayOrder as number,
                            name: data.name,
                            office: data.office,
                            url:
                                data.imageUrlId && data.imageUrlId !== ''
                                    ? process.env.REACT_APP_PHOTOURL +
                                      '/associates/' +
                                      data.imageUrlId +
                                      '_840x1120' +
                                      data.imageExtension
                                    : '',
                        },
                    ];
                    dispatch(
                        updateMainDevelopmentData({
                            agents: [...existingAgents, ...addedAgent],
                        }),
                    );
                    dispatch(setSuccess(`Agent is added successfully`));
                }
            } else {
                const response = await deleteAgentsApi(developmentId, agentId || '');
                if (response.status === 204) {
                    const updatedAgentsAfterDelete = existingAgents
                        .filter((agent) => agent.agentId !== agentId)
                        .map((data, index) => {
                            return {
                                ...data,
                                displayOrder: index + 1,
                            };
                        });
                    dispatch(setSuccess(`Agent is removed successfully`));
                    dispatch(
                        updateMainDevelopmentData({ agents: updatedAgentsAfterDelete }),
                    );
                }
            }
        } catch (exception) {
            dispatch(setError(`Failed to update development agents details`));
            Logger.error(
                `Failed to update development agents details ${JSON.stringify(
                    exception,
                )}`,
            );
        }
    };

/**
 * function add/remove agent from the list
 * @param data
 * @returns
 */
export const updateListings =
    (
        developmentId: string,
        from: string,
        deletedListingId: string,
        addedListings: DevelopmentListing[],
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const existingListings =
                getState().development.developmentDetails.data.listings;
            const addedListingIds = addedListings.map(
                (addedListing) => addedListing.listingId,
            );
            if (from === 'add') {
                const response = await updateListingsApi(developmentId, addedListingIds);
                if (response.status === 200) {
                    dispatch(
                        updateMainDevelopmentData({
                            listings: [...existingListings, ...addedListings],
                        }),
                    );
                    dispatch(setSuccess(`Listings have been added successfully`));
                }
            } else if (from === 'delete') {
                const response = await deleteListingsApi(developmentId, deletedListingId);
                if (response.status === 204) {
                    const updatedListings = existingListings.filter(
                        (listing) => listing.listingId !== deletedListingId,
                    );
                    dispatch(
                        updateMainDevelopmentData({
                            listings: [...updatedListings],
                        }),
                    );
                    dispatch(setSuccess(`Listings have been removed successfully`));
                }
            }
        } catch (exception) {
            dispatch(setError(`Failed to update development Listings details`));
            Logger.error(
                `Failed to update development Listings details ${JSON.stringify(
                    exception,
                )}`,
            );
        } finally {
            dispatch(fetchDevelopmentUnits(developmentId));
        }
    };

export const uploadDevVideo =
    (
        data: {
            developmentId: string;
            file: File;
            displayOrder: number;
        },
        abortController: AwsAbortController,
    ): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateDevLogoFlagStatus({ video: { isUploading: true } }));
            if (data) {
                const createBrightcove = {
                    videoType: videoTypes.buildingVideo.value,
                    fileName: data.file.name,
                    displayOrder: data.displayOrder,
                };
                const s3Details = await postVideoMetaData(
                    data.developmentId,
                    createBrightcove,
                );
                if (s3Details) {
                    dispatch(getVideos(data.developmentId, entityTypes.building.key));
                    const s3Uploaded = await s3Upload(
                        data.file,
                        s3Details,
                        abortController,
                    );
                    if (s3Uploaded === 'success') {
                        const apiUrl = process.env.REACT_APP_APIURL;
                        const test = {
                            master: {
                                url: s3Details.api_Request_Url,
                            },
                            callbacks: [`${apiUrl}/brightcove/callback`],
                            transcriptions: [
                                {
                                    srclang: 'en-US',
                                    kind: 'captions',
                                    label: 'captions on',
                                    status: 'published',
                                    default: true,
                                },
                            ],
                        };
                        const injRes = await ingestVideo(test, s3Details.video_Id);
                        if (injRes) {
                            const sira = [{ id: '5699924528001' }];
                            await shareVideo(s3Details.video_Id, sira);
                        } else {
                            dispatch(setError('Injest request is failed'));
                        }
                    }
                } else {
                    dispatch(setError('Video is not created in Brightcove'));
                }
            }
        } catch (err) {
            Logger.error(`Video is not uploaded: ${JSON.stringify(err)}`);
            dispatch(setError('Video is not uploaded'));
        } finally {
            dispatch(updateDevLogoFlagStatus({ video: { isUploading: false } }));
        }
    };

export const saveDevAmenities =
    (amenities: ListingAmenity[], developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            await saveDevelopmentAmenities(amenities, developmentId);
            dispatch(isFeatureUpdated(false));
            dispatch(setSuccess('Development amenities saved successfully'));
        } catch {
            Logger.error(`Error saving Development amenities: ${developmentId}`);
            dispatch(setError('Error saving Development amenities'));
        }
    };

export const fetchDevelopmentLogo =
    (developmentId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await getImages(
                entityTypes.building.value,
                developmentId,
                imageTypes.buildingLogos,
            );
            if (response.length > 0) {
                const logo: DevelopmentImageObject[] = response.map((image) => {
                    return {
                        imageUrl: image.urls.originalUrl,
                        isSmall: false,
                        isLandscape: false,
                        orderNo: 1,
                        imageTypeId: 6,
                    };
                });
                dispatch(updateMainDevelopmentData({ logo: logo }));
            }
        } catch {
            Logger.error(`Error in getting the development ${developmentId} logo`);
            dispatch(setError('Error in getting the development logo'));
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const uploadDevelopmentLogo =
    (image: ImageDetail): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateDevLogoFlagStatus({ logo: { isUploading: true } }));
            const imageFormData = jsonToFormData(image);
            const response = await savePhoto(imageFormData);
            if (response) {
                const logo: DevelopmentImageObject = {
                    imageUrl: response.originalUrl,
                    isSmall: false,
                    isLandscape: false,
                    orderNo: 1,
                    imageTypeId: 6,
                };
                dispatch(updateMainDevelopmentData({ logo: [logo] }));
                dispatch(setSuccess('Development logo is uploaded successfully'));
            }
        } catch (e) {
            Logger.error(`Error in uploading the development logo`);
            dispatch(setError('Error in uploading the development logo'));
        } finally {
            dispatch(updateDevLogoFlagStatus({ logo: { isUploading: false } }));
        }
    };

export const deleteDevelopmentLogo =
    (deleteImage: ImageDeleteData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateDevLogoFlagStatus({ logo: { isDeleting: true } }));
            const response = await deletePhoto(deleteImage);
            if (response) {
                dispatch(updateMainDevelopmentData({ logo: [] }));
                dispatch(setSuccess('Development logo is deleted successfully'));
            }
        } catch (e) {
            Logger.error(`Error in deleting the development logo`);
            dispatch(setError('Error in deleting the development logo'));
        } finally {
            dispatch(updateDevLogoFlagStatus({ logo: { isDeleting: false } }));
        }
    };

export const updateDevPublishStatus =
    (developmentId: string, publish: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const response = await updateDevPublish(developmentId);
            if (response.status === 204) {
                dispatch(updateMainDevelopmentData({ publish: !publish }));
                dispatch(setSuccess('Development publish is updated successfully'));
            }
        } catch (e) {
            Logger.error(`Error in updating the development publish`);
            dispatch(setError('Error in updating the development publish'));
        }
    };

export const updateDevAgentsDisplayOrders =
    (
        developmentId: string,
        updatedAgents: AgentDNDItem[],
        existingAgents: DevelopmentAgent[],
    ): AppThunk =>
    async (dispatch) => {
        try {
            const orderedAgents: AgentReorderRequest[] = updatedAgents.map(
                (devAgent: AgentDNDItem) => {
                    return {
                        agentId: devAgent.agentId,
                        displayOrder: devAgent.displayOrder,
                    };
                },
            );
            const response = await updateDevAgentsDisplayOrdersApi(
                developmentId,
                orderedAgents,
            );
            if (response.status === 204) {
                let devAgents: DevelopmentAgent[] = [];
                updatedAgents.forEach((agent) => {
                    let existingDevAgent = existingAgents.find(
                        (ex) => ex.agentId === agent.agentId,
                    );
                    if (existingDevAgent) {
                        existingDevAgent = {
                            ...existingDevAgent,
                            displayOrder: agent.displayOrder,
                        };
                        devAgents = [...devAgents, existingDevAgent];
                    }
                });
                dispatch(updateMainDevelopmentData({ agents: devAgents }));
                dispatch(setSuccess('Building agents orders are successfully updated'));
            }
        } catch (e) {
            Logger.error(`Failed to update the building agents display orders`, e);
            dispatch(setError(`Failed to update the building agents display orders`));
        }
    };

export const {
    updateLoader,
    setDevelopmentDetails,
    updateMainDevelopmentData,
    setInitialState,
    updateDevelopmentEditFormName,
    closeEditForm,
    setLoader,
    setSearchedAgent,
    updateOpenEditFromList,
    setCurrentDevelopmentEditFormData,
    updateDevVideoStatus,
    isFeatureUpdated,
    updateDevLogoFlagStatus,
} = developmentDetailsSlice.actions;

export const developmentDetails = (state: RootState): DevelopmentDetailsState =>
    state.development.developmentDetails;

export default developmentDetailsSlice.reducer;
